import {
  ComponentsService,
  EventMap,
  Isform2Component,
  LoadComponent,
  Outlet2Service,
  TrackLoading,
} from '@ic-builder/is-base';
import {
  BehaviorSubject,
  Observable,
  of,
  Subscriber,
  Subscription,
  timer,
} from 'rxjs';
import {
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { IscelComponent } from './isbody/iscel/iscel.component';
import {
  IscolumnComponent,
  IsColumnDef,
  IsrowDef,
} from './isbody/iscolumn/iscolumn.component';

import { Isheadercolumn1Component } from './isheader/isheadercolumn/isheadercolumn.component';
import {
  ClearLoadedSet,
  ExecQry,
  LoadDataset,
  RemoveDataset,
} from './../isapplication/isapplication.action';
import { IsApplicationState } from './../isapplication/isapplication.state';
import { Select, Store } from '@ngxs/store';
import {
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injectable,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IsCloudService } from '@ic-builder/data-access-iscloud';
import { Overlay } from '@angular/cdk/overlay';
import { LeafExpressionFormData } from '../isfilter/isleafexpression/isleafexpression.component';
import { ExpressionFormData } from '../isfilter/isexpression/isexpression.component';
import { Expression } from '../isfilter/expression.model';
import { WidgetsRegistryService } from '@ic-builder/widgets-registry';
import { SubSink } from 'subsink';
import produce from 'immer';
import { CvcolorPipe } from '../color/color-helpers.service';
import { ActivatedRoute } from '@angular/router';

/*export const testVal: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const bon = control.get('bonnr').value;
    const daynr = control.get('daynbr').value;

    return Number(bon)<Number(daynr)? { testVal: true } : null;
  };*/

@Injectable({
  providedIn: 'root',
})
export class IsGridRegistry {
  views: Map<number, IsGridService> = new Map<number, IsGridService>();

  add(id: number, view) {
    this.views.set(id, view);
  }

  get(id: number) {
    return this.views.get(id);
  }

  del(id: number) {
    this.views.get(id);
    this.views.delete(id);
  }
}

@Injectable({
  providedIn: 'root',
})
export class IsGridService implements OnDestroy {
  #subs = new SubSink();
  datasetsubs: Subscription[] = [];
  #info: any;

  @Select(Outlet2Service.getInDesigner) inDesigner: Observable<boolean>;
  isInDesigner = false;

  counter_test = 0;
  userowselect:boolean = true;
  datamode: number;
  browsemode = 2;
  autoprimary = false;
  primarykeys: string[];
  tablename: string;
  data: any;
  updateddata: any = [];
  deleteddata: any[];
  pivots: any[];
  virtualscroll: boolean;
  account: string;

  columns: Record<string, IsColumnDef>;
  fielddefs: any;
  columnKeys: string[] = [];
  cardBuffer: ComponentRef<any>[] = [];

  rowfunctions: any;
  columnkeyfunction: any;
  controlids = [];

  activeEvents = [];

  headerContainer: ViewContainerRef;
  headerCompRef: any = {};
  //ComponentRef<any>[] = [];

  bodyContainer: ViewContainerRef;
  bodyCompRef: any = {};
  fakeScroll: ElementRef;
  fakeScrollHorz: ElementRef;
  //ComponentRef<IscolumnComponent>[] = [];

  footerContainer: ViewContainerRef;
  footerCompRef: ComponentRef<any>[] = [];

  filterpanelSubscription: any;
  filterlistSubscription: any;
  expressionFilters = [];

  keepSortingstate : boolean = false;
  keepFiltermode : boolean = false;
  keepFilterstate : boolean = false;
  keepCurrentrecord : boolean = false;

  gridref: any;

  celCompRef: any = [];
  //ComponentRef<IscelComponent>[][] = []

  minheight: number;
  minwidth: number;
  gridHeight: number;
  rowheight = 30;
  _rowHeight: number;
  translatey: number;
  pivot: boolean;
  pivotdynamicrows = false;
  detailkey: string = null;
  newNumber = '';
  rowclass: string = null;
  selectedrowclass: string = null;

  columnsInView: string[] = [];

  selectedRowId = 0;

  get selectedRow() {
    return this.datax[this.scrolledItems + this.selectedRowId];
  }

  selectedColumnId:string;
  scrolledItems = 0;

  scrollbar: any;
  focusedCelRef: any;

  gridid: number;
  DataSubscription: Subscription;
  _editmode: number;
  datasetCache = false;

  editmode: boolean;

  hasFormGroup: boolean;
  totalStack: Array<any> = null;

  filter: Map<string, string> = new Map<string, string>();
  expFilter: Expression[] = [];
  datasets: Map<string, any> = new Map<string, any[]>();
  filtereddata: Map<string, any> = new Map<string, any[]>();
  filtered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  //afterscroll$: BehaviorSubject<RecordEvent> = new BehaviorSubject<RecordEvent>({ rowid: null, data: null });
  sortedcolumns: string[] = [];
  selectedcards: Map<string, ComponentRef<Isform2Component>>;
  searchstr: string;
  markstr: string;
  searchCell: ComponentRef<IscelComponent> = null;
  checkcachevalid: Function;
  info: any;
  removedataset = true;
  gridFormGroup = new FormGroup({});
  masterformGroup = null;
  headerHeight: number;
  footerHeight: number;
  keyfield: string;
  updatecommand: string;
  deletecommand: string;
  insertcommand: string;
  selectedRecord: any;
  returnToRecord: any;
  cardlist: ComponentRef<Isform2Component>[] = [];
  prevselected: Array<any> = null;
  aftersetData: Function;
  isheader: any;
  isbody: any;
  isfooter: any;

  get gridcomp() {
    return this.comp.widgets.get(this.gridid).instance;
  }

  get getAbsoluteRowIndex(){
    return {rowid:this.selectedRowId,scrolleditems:this.scrolledItems};
  }

  get datax() {
    if (this.filtereddata.size > 0) {
      return this.filtereddata.get('set1');
    } else {
      return this.data;
    }
  }

  get numberOfRows(): number {
    const i = this.getfirstvisiblecol();
    if (this.columnKeys.length == 0) {
      this.getColumnKeys(this.gridref);
    }
    if (i > -1) {
      this.headerHeight = this.headerCompRef[this.columnKeys[i]]
        ? this.headerCompRef[
            this.columnKeys[i]
          ].location.nativeElement.getBoundingClientRect().height
        : 0;

      if (this.footerCompRef.length) {
        this.footerHeight = this.footerCompRef[this.columnKeys[i]]
          ? this.footerCompRef[
              this.columnKeys[i]
            ].location.nativeElement.getBoundingClientRect().height
          : 0;
      } else {
        this.footerHeight = 0;
      }

      this._rowHeight =
        JSON.parse(
          this.bodyCompRef[this.columnKeys[i]]
            ? this.columns[this.columnKeys[i]].rowheight ??
                this.rowheight ??
                this.bodyCompRef[
                  this.columnKeys[i]
                ].location.nativeElement.getBoundingClientRect().height
            : 30
        ) + 1.1111;
      const bodyHeight =
        (this.gridHeight ? this.gridHeight : this.minheight) -
        this.headerHeight -
        this.footerHeight;
      const max = Math.floor(bodyHeight / this._rowHeight) + 1;
      const l = this.datax ? this.datax.length : 0;
      return l < max ? l : max;
    } else {
      return 1;
    }
  }

  get scrollbarHeight(): number {
    if (this.datax) {
      const l = this.datax?.length == 0 ? 1 : this.datax.length;
      const r = this.numberOfRows;
      return (100 * r) / l >= 100 ? 0 : (100 * r) / l;
    } else {
      return 0;
    }
  }

  gridLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  selectCheckboxColumn = false;
  selectHeadervisible: boolean;
  removedSearchCell = null;

  constructor(
    public store: Store,
    public compFactory: ComponentFactoryResolver,
    public registry: WidgetsRegistryService,
    public appservice: IsApplicationState,
    public comp: ComponentsService,
    public outlet: Outlet2Service,
    public httpClient: HttpClient,
    public IsCloudService: IsCloudService,
    public renderer: Renderer2,
    public gridregistry: IsGridRegistry,
    public overlay: Overlay,
    public colorpipe: CvcolorPipe,
    private route: ActivatedRoute
  ) {
    this.#subs.sink = this.inDesigner.subscribe((isIn) => {
      this.isInDesigner = isIn;
    });
    this.definefunctions();
  }

  getfirstvisiblecol() {
    let i = this.selectCheckboxColumn ? 1 : 0,
      j = -1;
    if (this.columns) {
      const tmp = Object.values(this.columns);
      if (tmp) {
        while (i < tmp.length) {
          if (tmp[i] != null) {
            if (!tmp[i].hidden) {
              j = i;
              break;
            }
          }
          i++;
        }
      }
    }
    return j;
  }

  calcReducers(data) {
    this.gridref.reducers.forEach((reducer) => {
      if (reducer){
      switch (reducer.type) {
        case 'sum':
          reducer['value'] = this.sum(data, reducer);
          break;
        case 'sub':
          reducer['value'] = this.subtract(data, reducer);
          break;
        case 'avg':
          reducer['value'] = this.average(data, reducer);
          break;
        case 'min':
          reducer['value'] = this.min(data, reducer);
          break;
        case 'max':
          reducer['value'] = this.max(data, reducer);
          break;
      }
     }
    });
  }

  setFooterData(reducers) {
    Object.entries(this.footerCompRef).forEach(([key, item]) => {
      if (this.columns[key].footercomp == 'reducer') {
        const reducer = this.gridref.reducers.find((red) => {
          return this.columns[item.instance.colid].reducer == red.name;
        });
        if (reducer) {
          item.instance.label = reducer['value'];
        }
      }
    });
  }

  setData(data) {
    this.datasets.set('set1', data);
    this.data = data;
    if (this.gridref.aftersetData) {
      this.gridref.aftersetData(data, this.gridref, this);
    }
    if (this.gridref.reducers) {
      this.calcReducers(data);
      this.setFooterData(this.gridref.reducers);
    }
  }

  setSelectedData(data) {
    if (data == null || (data.length == 0)) {
      this.datasets.delete('selected');
    } else {
      this.datasets.set('selected', data);

      const select =
        this.headerCompRef?.select?.location?.nativeElement.querySelector(
          'iscustomcheckbox'
        );
      if (select && this.selectHeadervisible) {
        if (this.data.length === data.length) {
          select.classList.add('check');
        } else if (data.length > 0) {
          select.classList.remove('check');
          select.classList.add('notallcheck');
        } else {
          select.classList.remove('check');
          this.headerCompRef.select.instance.formControl.setValue(false);
        }
        this.headerCompRef.select.changeDetectorRef.detectChanges();
      }
      this.triggerDetail(data);
      if (this.gridref.selectedformControl){
        this.gridref.selectedformControl.setValue(data);
      }
    }
  }

  updateCheckboxes() {
    if (this.selectCheckboxColumn) {
      let datapos = 0;
      this.celCompRef.forEach((rowRef) => {
        const coldef: any = this.columns['select'];
        this.fillCell(rowRef['select'], datapos++, coldef);
      });
    }
  }

  clearCheckboxes() {
    this.datasets.set('selected',[])
    this.updateCheckboxes();
    this.selectRow(0);
  }

  refreshColumn(colname: string) {
    let datapos = 0;
    this.celCompRef.forEach((rowRef) => {
      const coldef: any = this.columns[colname];
      this.fillCell(rowRef[colname], datapos++, coldef);
    });
  }

  setInitalRow(key: string, value: number | string) {
    if (!this.data?.length) return;
    if (this.data.map((d) => d[key]).includes(value)) {
      let moreRecords = 0;
      let teller = 0;
      while (this.selectedRow[key] != value && moreRecords < 2) {
        if (this.selectedRowId < this.numberOfRows - 1) {
          console.log('one row');
          this.selectRow(this.selectedRowId + 1);
        } else {
          //this.selectRow(this.selectedRowId + 1);
          console.log('move viewdown ', teller++);
          moreRecords = moreRecords + this.moveViewDown();
          if (moreRecords == 1) {
            this.refreshview();
          }
        }

        // if(this.selectedRowId>this.numberOfRows){
        //   this.moveViewDown()
        // } else {
        //   this.selectRow(this.selectedRowId+1)
        // }
      }

      //const selectedindex = this.celCompRef.findIndex(c => c[key].instance.label==this.selectedRow[key])
      //this.selectedRowId = selectedindex;
      //set styling
      //Object.values(this.celCompRef[selectedindex]).map((c:ComponentRef<any>) => {c.location.nativeElement.classList.add('row-selected')})
    }
  }

  addRow(row?,formatdefinition?:any) {
    if (!row) {
      row = {};
      for (const k of this.columnKeys) {
        row[k] = '';
      }
    }

    let data = this.datasets.get('set1');
    data = data ? [...this.datasets.get('set1'), row] : [row];

    this.clearGrid();
    this.setData(data);

    if (this.datasets.get('set1').length <= this.numberOfRows) {
      //grid.selectRow(grid.data.length-1)
    } else {
      this.scrolledItems =
        this.datasets.get('set1').length - this.numberOfRows - 1;
    }
    this.fillData(data);
    this.selectRow(data.length > this.numberOfRows ? this.numberOfRows - 1 : data.length - 1);

    if (formatdefinition){
      const rowRef = this.celCompRef[this.selectedRowId];
      const pos = this.selectedRowId;
      for (const [key, col] of Object.entries(rowRef)) {
        if ((col as any).instance) {
          const coldef: any = this.columns[key];
          if (this.columns[key].format){
            if (this.columns[key].datatype == 'datetime' && this.datax[pos][key]){              
              this.datax[pos][key] = this.comp.setdatetoformat(this.datax[pos][key],formatdefinition[key],this.columns[key].format)
            }
          }
          this.fillCell(rowRef[key], pos, coldef);
        }
        (col as ComponentRef<IsColumnDef>).changeDetectorRef.detectChanges();
      }
    }
    
  }

  swapRows(idx, idx1) {
    if (idx > 0) {
      const data = this.datasets.get('set1');
      if (data.length > 1) {
        const d = data.splice(idx, 1);
        data.splice(idx1, 0, d[0]);
      }
      this.clearGrid();
      this.setData(data);
      this.fillData();
    }
  }

  removeRows(toremove?) {
    let data;
    if (toremove) {
      data = this.datasets.get('set1').filter((d) => {
        return !toremove.includes(d);
      });
    } else {
      data = this.datasets.get('set1').filter((d) => {
        return !d.select;
      });
    }
    this.clearGrid();

    this.setData(data);
    this.fillData();
  }

  removeSelectedRow() {
    const d = this.datax[this.selectedRowId];
    this.removeRows([d]);
  }
  /* filter on all cols of dataset */
  checkFilter(d, filter, col?) {
    for (const key of Object.keys(d)) {
      if (filter.get(key)) {
        if (!d[key]) {
          return false;
        }
        if (
          !d[key]
            .toString()
            .toLowerCase()
            .includes(filter.get(key).toLowerCase())
        ) {
          return false;
        }
      }
    }
    return true;
  }
  /* filter on one specific col of dataset */
  checkFiltercol(d, filter, col) {
    if (filter.get(col)) {
      if (!d[col]) {
        return false;
      }
      if (typeof d[col] == 'number') {
        return (
          d[col].toString().toLowerCase() ==
          filter.get(col).toString().toLowerCase()
        );
      }
      if (typeof d[col] == 'string') {
        const s = d[col].toLowerCase().split(',');
        return s.includes(filter.get(col));
        //if (!d[col].toString().toLowerCase().includes(filter.get(col).toLowerCase())) {
        // return false
        //}
      }
    }
    return true;
  }

  eventsFired = [];

  searchData(col, key, awaittime = 250, aftersearch?: any, afp?: any) {
    //if (!this.comp.isMobile()){
    this.setSearchString(col, key);
    //}
    let currentview;
    let markingindex;

    this.eventsFired.push(key);
    const currentLength = this.eventsFired.length;

    const search = () => {
      if (this.searchstr) {
        let partMatch = false;
        const regex = new RegExp('^' + this.searchstr, 'i');
        for (
          let i = this.scrolledItems;
          i < this.scrolledItems + this.numberOfRows && i < this.datax.length;
          i += 1
        ) {
          //console.log('currentview');
          if (regex.test(this.datax[i][col])) {
            currentview = true;
            markingindex = i - this.scrolledItems;
            partMatch = true;
            break;
          }
        }
        if (!currentview) {
          for (let i = 0; i < this.datax.length; i += 1) {
            //console.log('no currentview');
            if (regex.test(this.datax[i][col])) {
              this.scrolledItems = i;
              partMatch = true;
              break;
            }
          }
        }
        if (partMatch) {
          if (currentview) {
            
            this.removemarkingSearch('c',false);
            //if (!this.comp.isMobile()){
              this.focusedCel(this.focusedCelRef, markingindex, col);
            //}
            this.selectRow(markingindex);
          } else {

            markingindex = this.numberOfRows - 1;
            if (this.scrolledItems < this.numberOfRows) markingindex = 0; 

            
            this.removemarkingSearch('d',false);
            console.log('set initial row',false);
            this.updateScroll(this.celCompRef, this.scrolledItems - markingindex);

            this.scrolledItems = this.scrolledItems - markingindex;
            // this.setInitalRow(col, value);
            //if (!this.comp.isMobile()){
              this.focusedCel(this.focusedCelRef, markingindex, col);
            //}
            this.selectRow(markingindex);

            this.updateScrollbarPosition();
          }
          this.updateMarkingSearch(col, markingindex,'a');
          return true;
        } else {
          this.searchstr = this.searchstr.substring(0, this.searchstr.length - 1);
          return false;
        }
      }
    };

    timer(awaittime).subscribe((c) => {
      if (currentLength < this.eventsFired.length) return;
      //console.log('start search');
      let res = search();

      while (!res && this.eventsFired.length > 0) {        
        res = search();       
        this.eventsFired.pop();
      }
      if (res) {
        console.log('searched', this.searchstr);        
      }
      if (aftersearch){
        aftersearch(afp);
      }

      this.eventsFired = [];
    });
    //let Pages = Math.trunc(this.datax.length / this.numberOfRows);    //const remainder = this.datax.length % this.numberOfRows;
    //if (!(remainder==0)){
    //  Pages++
    // }
    // const Page = Math.trunc(this.scrolledItems / this.numberOfRows);
    // const offset = Page*this.numberOfRows;
    // } else {
    //   markingindex = this.scrolledItems;
    //   this.removemarkingSearch(false);
    //   this.updateScroll(this.celCompRef, this.scrolledItems);
    //   this.updateScrollbarPosition();
    //   this.focusedCel(this.focusedCelRef, this.selectedRowId, col);
    //   this.selectRow(this.scrolledItems);
    // }
  }

  removemarkingSearch(from,empty = true){
    if (this.searchCell) {      
      //console.log('remove marking ssearch ',empty,' ',this.searchstr,' ',from);
      if(empty) this.searchstr = '';
      this.celCompRef[this.selectedRowId][this.selectedColumnId].instance.searchstr = '';//empty?'':this.searchstr;
      this.celCompRef[this.selectedRowId][this.selectedColumnId].changeDetectorRef.detectChanges();
    }
    //console.log('no search cell');
  }

  updateMarkingSearch(col = null, index = null,from = null){
    if (this.celCompRef[index][col].instance.iscell) {
      //console.log('update marking search ',this.searchstr,from );
      this.celCompRef[index][col].instance.searchstr = this.searchstr;
      this.searchCell = this.celCompRef[index][col].instance;
      this.celCompRef[index][col].changeDetectorRef.detectChanges();
    }
  };

  setSearchString(col, key) {
    const currentVal = '';
    if (!this.searchstr) {
      this.searchstr = '';
    }

    if (key == 'Backspace') {
      this.searchstr = this.searchstr.substring(0, this.searchstr.length - 1);
      if (this.searchstr.length == 0) {
        //this.resetMarkingSearch();
        //this.removemarkingSearch(true,true);
        this.removemarkingSearch('e',false);
      }
    }
    if (key.length == 1) {
      this.searchstr += key;
    } else
    {
      this.searchstr = key;
    }
  }

  filterDataex(col, key) {
    this._filterData(col, key, this.setfunctionFilter, this.checkFiltercol, null, null);
    if (this.gridref.aftersetData) {
      this.gridref.aftersetData(this.datax, this.gridref, this);
    }
  }

  filterData(col, key) {
    this._filterData(col, key, this.setFilter, this.checkFilter, this.markfilter, this.focusedCel);
    if (this.gridref.aftersetData) {
      this.gridref.aftersetData(this.datax, this.gridref, this);
    }
  }

  markfilter = (col) => {
    Object.entries(this.celCompRef).forEach(([k, v]) => {

      this.filter.forEach((value, key) => {
        v[key].instance.searchstr = value;
        v[key].changeDetectorRef.detectChanges();
      })
      // let str = v[col].instance.label.toString();
      // if (this.filter.get(col)) {
      //   str = str.replace(new RegExp(this.filter.get(col), 'gi'), (match) => `<mark>${match}</mark>`);
      // }
      // v[col].location.nativeElement.innerHTML = str;
      // (v[col] as ComponentRef<IscelComponent>).changeDetectorRef.detectChanges();
      // v[col].instance.searchstr = this.filter.get(col);
    });
  };

  _filterData(col, key, fn, fnCheck, fnmarkFilter, fnfocusedCel) {
    fn(col, key, this.filter);
    this.execFilter(fnCheck, col);
    if (fnmarkFilter) {
      fnmarkFilter(col);
    }
    if (fnfocusedCel) {
      fnfocusedCel(this.focusedCelRef, this.selectedRowId, col);
    }
    this.syncafterFilter(0);
  }

  syncafterFilter(index) {
    this.selectRow(index);
    this.triggerDetail(this.filtereddata.get('set1')[index]);
  }

  isLeaf(expression: LeafExpressionFormData | ExpressionFormData): expression is LeafExpressionFormData {
    return expression ? (expression as LeafExpressionFormData).field !== undefined : false;
  }

  expressionToPredicate(d, exp) {
    if (exp) {
      if (this.isLeaf(exp)) {
        if (exp['operator'] && exp['field'] && exp['value']) {
          const operator = this.getOperatorFunction(exp['operator']);
          return operator(d[exp['field']], exp['value']);
        }
        return;
      } else {
        if (exp['leftExpression'] && exp['operator'] && exp['rightExpression']) {
          const operator = this.getOperatorFunction(exp['operator']);
          const left = this.expressionToPredicate(d, exp['leftExpression']);
          const right = this.expressionToPredicate(d, exp['rightExpression']);
          return operator(left, right);
        }
        return;
      }
    }
  }

  getOperatorFunction(operator) {
    switch (operator) {
      case '!=':
        return (x, y) => {
          return x != y;
        };
      case '<=':
        return (x, y) => {
          return x <= y;
        };
      case '>=':
        return (x, y) => {
          return x >= y;
        };
      case '==':
        return (x, y) => {
          return x == y;
        };
      case 'starts with':
        return (x, y) => {
          return x.toString().startsWith(y);
        };

      case 'OR':
        return (x, y) => {
          return x || y;
        };
      case 'AND':
        return (x, y) => {
          return x && y;
        };
    }
  }

  execExpressionFilter(exp?) {
    const data = this.datasets.get('set1');
    const filtered = [];
    if (exp) {
      data.map((d) => {
        if (this.expressionToPredicate(d, exp)) filtered.push(d);
      });
    } else {
      data.map((d) => {
        let bool = true;
        this.expFilter.forEach((ex) => {
          if (!this.expressionToPredicate(d, ex)) bool = false;
        });
        if (bool) filtered.push(d);
      });
    }
    if (filtered.length > 0) {
      this.filtereddata.set('set1', filtered);
      this.clearGrid();
      this.fillData(this.filtereddata.get('set1'));
      this.updateScrollbarPosition();
    } else {
      this.resetExpressionFilter();
    }
    this.filtered$.next(true);
  }

  execExprFilter(exp?) {
    const data = this.datasets.get('set1');

    //const filtered = [];

    const filtered = data.filter((d) => exp(d));

    //if (filtered.length > 0) {
    this.filtereddata.set('set1', filtered);

    if (this.pivot) {
      this.refreshPivotview(filtered);
    } else {
      this.clearGrid();
      this.fillData(this.filtereddata.get('set1'));
      this.updateScrollbarPosition();
    }
    //} else {
    //  this.resetExpressionFilter();
    //}
    this.filtered$.next(true);
  }

  resetExpressionFilter() {
    if (this.filtereddata.get('set1')) {
      if (this.filtereddata.get('set1').length > 0) {
        this.filtereddata.set('set1', []);
        if (this.pivot) {
          this.refreshPivotview(this.data);
        } else {
          this.clearGrid();
          this.fillData(this.data);
        }
      }
    }
  }

  execFilter(checkFilter, col?) {
    const data = this.datasets.get('set1');
    if (this.filter.size > 0) {
      let filtered = [];
      data.map((d) => {
        if (checkFilter(d, this.filter, col)) {
          filtered.push(d);
        }
      });
      if (this.sortedcolumns?.length != 0) {
        filtered = this.sortData(filtered);
      }
      this.filtereddata.set('set1', filtered);
    } else {
      let sorted = [];
      if (this.sortedcolumns?.length != 0) {
        sorted = this.sortData(data);
      }
      this.filtereddata.set('set1', sorted);
    }

    if (this.pivot) {
      const d = this.filtereddata.get('set1');
      this.refreshPivotview(d);
    } else {
      this.clearGrid();
      this.fillData(this.filtereddata.get('set1'));
    }

    if (this.filtereddata.get('set1').length === 0) {
      console.log('set focus on grid');
      this.gridref.el.nativeElement.focus();
    }

    this.filtered$.next(true);
  }

  clearfilter() {
    this.filter.clear();
    this.filtereddata.clear();
    if (this.pivot) {
      this.refreshPivotview(this.data);
    } else {
      this.clearGrid();
      this.fillData(this.data);
    }
    //this.updateScroll(this.celCompRef, this.scrolledItems);
    this.updateScrollbarPosition();
    //this.focusedCel(this.focusedCelRef, this.selectedRowId, col);
    this.selectRow(0);
  }

  refreshCards() {
    this.cardlist.map((item) =>
      this.setStylefunctions(
        item,
        item['columnDef'].functions,
        item.instance['data']
      )
    );
  }

  refreshCard(cardCompRef) {
    this.setStylefunctions(
      cardCompRef,
      cardCompRef['columnDef'].functions,
      cardCompRef.instance['data']
    );
  }

  findCard(data) {
    return this.cardlist.find((item) => {
      return item.instance == data;
    });
  }

  refreshPivotview(d) {
    this.clearGrid();
    this.setColumnKeys({ columns: this.#info.columns });
    this.columnKeys.map((key) => {
      this.columns[key].pivots.map((pivot) => (pivot['calculated'] = false));
      this.createPivotCols(
        this.columns[key].pivots,
        this.bodyCompRef[key],
        0,
        this.headerCompRef[key],
        this.columns[key]
      );
    });
    let prom
    for (const c of Object.values(this.columns)) {
      const col = c as any;
      if (c) {
        prom = this.fillData1([col.name], col, d);
      }
    }
    return prom;
  }

  refreshview(clearCardbuffer?:boolean) {
    if (this.pivot) {
      //if (!clearCardbuffer){this.clearCardbuffer()}
      this.refreshPivotview(this.data);
    } else {
      this.clearGrid();
      this.fillData(this.data);
    }
    this.updateScrollbarPosition();
    this.selectRow(0);
  }

  setFilter(col, key, filter) {
    let currentVal = '';
    if (filter.get(col)) {
      currentVal = filter.get(col);
    }

    if (key == 'Backspace') {
      currentVal = currentVal.substring(0, currentVal.length - 1);
    }
    if (key.length == 1) {
      currentVal += key;
    }
    filter.set(col, currentVal);
  }

  resetFilter(){
    this.filter.clear();
  }

  setfunctionFilter(col, key, filter) {
    let currentVal = '';
    if (filter.get(col)) {
      currentVal = filter.get(col);
    }

    filter.set(col, key);
  }

  resetallsortedcolumns(colid) {
    for (const column of Object.values(this.headerCompRef)) {
      if ((column as any).instance.colid !== colid) {
        (column as any).instance.resetsort();
        (column as any).changeDetectorRef.detectChanges();
      }
    }
  }

  removeItems = (input, items) => {
    return input.filter(function (x) {
      let i = this.findIndex((y) => y === x);
      return i >= 0 ? (this.splice(i, 1), false) : true;
    }, items.slice());
  };

  addsortcolumn(col, updown, add?) {
    if (!add) {
      this.resetsortedcolumns();
    }
    const col1 = updown === 'down' ? '-' + col : col;

    const idx = this.sortedcolumns.indexOf(col1);
    if (idx >= 0) {
      this.sortedcolumns[idx] = col1;
    } else {
      this.sortedcolumns.push(col1);
    }
    let sorted = [];
    if (
      this.sortedcolumns[0] == 'select' ||
      this.sortedcolumns[0] == '-select'
    ) {
      const sel = [...this.datasets.get('selected')];
      const set1 = [...this.datasets.get('set1')];
      sorted = this.sortData(this.removeItems(set1, sel));
      if (this.sortedcolumns[0] == '-select') {
        sel.forEach((item) => sorted.push(item));
      } else {
        sel.forEach((item) => sorted.unshift(item));
      }
    } else if (this.sortedcolumns.length != 0) {
      if (this.browsemode==1 && this.filtereddata.size>0){
        sorted = this.sortData(this.filtereddata.get('set1'));
        //this.updateMarkingSearch(col,null,'x');
      } else
      {
        sorted = this.sortData(this.datasets.get('set1'));
      }
    }
    this.filtereddata.set('set1', sorted);
    this.clearGrid();
    this.fillData(this.filtereddata.get('set1'));
    if (this.focusedCelRef) {
      this.focusedCel(
        this.focusedCelRef,
        this.selectedRowId,
        this.selectedColumnId
      );
    }
    (this.headerCompRef[col] as any).changeDetectorRef.detectChanges();
    this.filtered$.next(true);
  }

  removesortcolumn(col) {
    this.sortedcolumns = this.sortedcolumns.filter(function (
      value,
      index,
      arr
    ) {
      return value !== arr[index];
    });
  }

  resetsortedcolumns() {
    this.sortedcolumns = [];
  }


  fieldSorter = (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        
        return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  sortData(data) {
    //this.removemarkingSearch('f');
    const defsort = this.fieldSorter;
    if (this.gridref.fieldsorters){
      const fn = this.gridref.fieldsorters[this.sortedcolumns[0]];
      const f1 = fn?fn:defsort;
      return data?.sort(f1(this.sortedcolumns));
    } else
    {
      return data?.sort(defsort(this.sortedcolumns));
    }
  }

  clearColumns() {
    console.log('clear grid columns : ',this.gridref.id);
    while (this.headerContainer.length > 0) {
      this.headerContainer.remove(0);
    }
    while (this.bodyContainer.length > 0) {
      this.bodyContainer.remove(0);
    }
    for (let i = 0; i < this.columnKeys.length; i++) {
      this.bodyCompRef.delete(this.columnKeys[i]);
    }
    while (this.footerContainer.length > 0) {
      this.footerContainer.remove(0);
    }
  }

  clearGrid(cd?) {
    console.log('clear grid : ',this.gridref.id);
    this.cardlist = [];
    this.columnKeys = [];
    for (const column of Object.values(this.bodyCompRef)) {
      while ((column as any).instance.columnContainer.length > 0) {
        (column as any).instance.columnContainer.remove(0);
      }
      if (cd) {
        (column as any).changeDetectorRef.detectChanges();
      }
    }
    this.celCompRef = [];
    this.selectedRowId = 0;
    this.scrolledItems = 0;
  }

  resetView(){
    console.log('resetview  : ',this.gridref.id);
    this.clearGrid();
    this.setData(this.datax);
    this.fillData();
  }          

  removeView() {
    /*this.headerContainer.clear();
        this.bodyContainer.clear();*/

    while (this.headerContainer.length > 0) {
      this.headerContainer.remove(0);
    }

    while (this.bodyContainer.length > 0) {
      this.bodyContainer.remove(0);
    }

    this.data = [];
    this.celCompRef = [];
    this.selectedRowId = 0;
  }

  createmasterFormGroup(info) {
    for (const i of info) {
      const control = this.comp.formcontrols.get(i.controlname);
      if (!control) {
        return null;
      }
    }

    this.masterformGroup = new FormGroup({});

    for (const i of info) {
      const control = this.comp.formcontrols.get(i.controlname);
      if (control) {
        this.masterformGroup.addControl(i.key, control.formcontrol);
      }
    }

    this.masterformGroup.valueChanges
      .pipe(debounceTime(200))
      .subscribe((params) => {
        this.clearGrid();
        this.gridcomp.loadData(params);
      });
    return this.masterformGroup;
  }

  definerowFunctions(info) {
    const isrow: IsrowDef = {
      name: info.name ? info.name : info,
      state: info.state ? info.state : info,
      functions: info.functions ? info.functions : [],
    };
    isrow.functions = isrow.functions.map((f) => {
      return {
        func: new Function('data,gridview', f.func),
        styleprop: f.styleprop,
        qrysel: f.qrysel ? f.qrysel : null,
      };
    });
    return isrow;
  }

  definecolumnkeyFunction(info) {
    return new Function('data,columnname,colinstance', info);
  }

  definefunctions() {
    globalThis['isone'] = {
      isoweek: new Function(
        'w,y',
        'var simple = new Date(y, 0, 1 + (w - 1) * 7);' +
          'var dow = simple.getDay();' +
          'var ISOweekStart = simple;' +
          'if (dow <= 4) ' +
          'ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1); ' +
          'else ' +
          'ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay()); ' +
          'return ISOweekStart; '
      ),
      daterangeindays: new Function(
        'masterdata',
        'data',
        'return ["31-8-2020","1-9-2020","2-9-2020","3-9-2020","4-9-2020","5-9-2020","6-9-2020"]'
      ),
    };
  }

  getcell(info, type?) {
    let celltype = type ? type : 'iscel';
    if (info.datatype) {
      switch (info.datatype) {
        case 'integer':
          celltype = 'iscel';
          break;
        case 'string':
          celltype = 'iscel';
          break;
        case 'memo':
          celltype = 'iseditdialog';
          break;
        case 'binary':
          celltype = 'iseditdialog';
          break;
        case 'json':
          celltype = 'iseditdialog';
          break;
        case 'javascript':
          celltype = 'iseditdialog';
          break;
        case 'sql':
          celltype = 'iseditdialog';
          break;
        case 'objpascal':
          celltype = 'iseditdialog';
          break;
        case 'date':
          celltype = 'iscel';
          break;
        case 'boolean':
          celltype = 'iscel';
          break;
        default:
          celltype = 'iscel';
          break;
      }
    }
    return celltype;
  }

  getcelledit(info, type?) {
    let celltype = 'iscel';
    if (info.datatype) {
      switch (info.datatype) {
        case 'integer':
          celltype = 'isnumber';
          break;
        case 'binary':
          celltype = 'iseditdialog';
          break;
        case 'memo':
          celltype = 'iseditdialog';
          break;
        case 'json':
          celltype = 'iseditdialog';
          break;
        case 'javascript':
          celltype = 'iseditdialog';
          break;
        case 'sql':
          celltype = 'iseditdialog';
          break;
        case 'objpascal':
          celltype = 'iseditdialog';
          break;
        case 'date':
          celltype = 'isdate';
          break;
        case 'boolean':
          celltype = 'ischeckbox';
          break;
        case 'double':
          celltype = 'isnumber';
          break;
        default:
          celltype = type ? type : 'iscel';
          break;
      }
    }
    return celltype;
  }

  getstatics(celltype, info) {
    let stats = [];

    if (celltype) {
      switch (celltype) {
        case 'iseditdialog':
          stats = [
            { key: 'gridid', value: this.gridid },
            { key: 'colname', value: info.name },
            { key: 'options.language', value: info.datatype },
          ];
          break;
        case 'ischeckbox':
          stats = [{ key: 'gridid', value: this.gridid }];
          break;
        case 'isnumber':
          stats = [
            { key: 'formControl', value: this.gridFormGroup.get(info.name) },
            { key: 'formInput', value: false },
          ];
          break;
        default:
          stats = [{ key: 'formControl', value: this.gridFormGroup.get(info.name) }];
          break;
      }
    }
    return stats;
  }

  defineColumn(info) {
    const iscolumn: IsColumnDef = {
      name: info.name ? info.name : info,
      datatype: info.datatype != null ? info.datatype : null,
      format: info.format != null ? info.format : null,
      label: info.label ? info.label : info.name ? info.name : info,
      translation: info.translation ? info.translation : null,
      headervisible: info.headervisible != null ? info.headervisible : true,
      headercomp: info.headercomp ?? 'isheadercel',
      headerclass: info.headerclass ?? null,
      rowclass: info.rowclass ?? null,      
      columnclass: info.columnclass ?? null,
      footerclass: info.footerclass ?? null,
      footertext: info.footertext ?? null,
      path: info.path ?? null,
      reducer: info.reducer ?? null,
      bodycomp: info.bodycomp
        ? info.bodycomp.includes('form')
          ? 'isform'
          : info.bodycomp
        : 'iscel', //this.getcelledit(info,'iscel'),
      bodycompconfig: info.bodycompconfig
        ? info.bodycompconfig
        : { static: [], data: 'label' },
      footervisible: info.headervisible ? info.footervisible : true,
      footercomp: info.footercomp ? info.footercomp : 'iscel',
      customfilter: info.customfilter ? info.customfilter : null,
      rowheight: info.rowheight ?? null,
      editcomp: info.editcomp
        ? info.editcomp
        : this.getcelledit(info, 'editcel'),
      datatoedit: info.datatoedit
        ? info.datatoedit
        : {
            static: this.getstatics(
              info.editcomp ? info.editcomp : this.getcelledit(info),
              info
            ),
            data: 'formControl',
          },
      edittodata: info.edittodata
        ? info.edittodata
        : {
            static: this.getstatics(
              info.editcomp ? info.editcomp : this.getcelledit(info),
              info
            ),
            data: 'formControl.value',
          },
      controlid: info.controlid ? info.controlid : this.outlet.uniqueId,
      edit: info.edit ? true : false,
      selection: this.selectCheckboxColumn && info.bodycomp === 'ischeckboxcel',
      width: info.width ? info.width : '',
      flex: info.flex ? info.flex : info.pivots ? 1 : null,
      hidden: info.hidden ? info.hidden : false,
      dragstart: info.dragstart
        ? info.dragstart && info.dragstart !== ''
        : false,
      functions: info.stylefunctions ? info.stylefunctions : [],
      editfunction: info.afteredit ? info.afteredit : null,
      repeatfunction: info.repeatfunction ? info.repeatfunction : null,
      pivots: info.pivots ? info.pivots : null,
      renderdirection: info.renderdirection ? info.renderdirection : 'column',
      columnwidth: info.colwidthfunction ? info.colwidthfunction : null,
      trackby: info ? info.trackby : null,
      cellform: info ? info.cellform : null,
      formkey: info ? info.formkey : null,
      classdefs: info.classdefs ? info.classdefs : null,
      sticky: info.sticky ? info.sticky : null,
      stickycolor: info.stickycolor ? info.stickycolor : null,
      disablerowselected: info.disablerowselected
        ? info.disablerowselected
        : null,
      attribsetter: info.attribsetter  
    };

    if (iscolumn.bodycomp === 'isform') {
      if (!info.cellform) {
        iscolumn.cellform = Number(
          info.bodycomp.substring(4, info.bodycomp.length)
        );
      } else {
        iscolumn.cellform = info.cellform;
      }

      const loadCmp =
        typeof iscolumn.cellform === 'object'
          ? Object.values(iscolumn.cellform).map((c: any) => c.form)
          : [iscolumn.cellform];

      for (let l of loadCmp) {
        this.store.dispatch(new LoadComponent({ id: Number(l), name: '' }));
      }
    }

    if (!this.isInDesigner && iscolumn.columnwidth) {
      if (!iscolumn.columnwidth.includes('return')) {
        iscolumn.columnwidth = 'return ' + iscolumn.columnwidth;
      }
      iscolumn.columnwidth = new Function(
        'numberofrows,colheight',
        iscolumn.columnwidth
      );
    }

    if (!this.isInDesigner && iscolumn.functions.length > 0) {
      iscolumn.functions = iscolumn.functions.map((f) => {
        return {
          func: new Function('data,gridview,celref', f.func),
          styleprop: f.styleprop,
          qrysel: f.qrysel ? f.qrysel : null,
        };
      });
    }

    if (!this.isInDesigner && iscolumn.editfunction) {
      (iscolumn.editfunction = info.editfunction
        ? new Function('data,column,gridview', info.editfunction)
        : null),
        (iscolumn.afteredit = info.afteredit
          ? new Function('data,value', info.afteredit).bind(this)
          : null);
    }
    if (!this.isInDesigner && iscolumn.repeatfunction) {
      iscolumn.repeatfunction = iscolumn.repeatfunction
        ? {
            func: new Function(
              'column,master,repeatindex',
              iscolumn.repeatfunction.func
            ),
            repeatcount: iscolumn.repeatfunction.repeatcount,
          }
        : null;
    }

    if (!this.isInDesigner) {
      if (iscolumn.classdefs) {
        const tmpDef = iscolumn.classdefs;
        delete iscolumn.classdefs;
        this.comp.createClassDefinitions(iscolumn.name, tmpDef);
      }
      this.calcPivots(iscolumn);
    }

    return iscolumn;
  }

  calcPivots(iscolumn) {
    if (iscolumn.pivots) {
      iscolumn.pivots.map((pivotdef) => {
        //if (!pivotdef['pivotdef']) {
        //  pivotdef['pivotdef'] = JSON.parse(JSON.stringify(pivotdef.pivot));
        //}
        if (typeof pivotdef.pivot == 'object') {
          if (pivotdef?.pivot?.function) {
            //                           console.log('pivot is function');
            this.pivotdynamicrows = true;
            pivotdef.gridfunction = {
             // func: new Function('masterdata', 'data', pivotdef.pivot.function),
              func: pivotdef.pivot.function,
              masterdata: pivotdef.pivot,
              data: this.datax,
              owner: this.gridref
            };
          }
          if (pivotdef.pivot?.onclick) {
            pivotdef.onclick = {
              func: new Function(
                'ev',
                'data',
                'self',
                'pivots',
                'index',
                pivotdef.pivot.onclick
              ),
              data: this.datax,
            };
          }
          if (pivotdef.pivot?.oncontextmenu) {
            pivotdef.oncontextmenu = {
              func: new Function(
                'ev',
                'data',
                'self',
                'pivots',
                'index',
                pivotdef.pivot.oncontextmenu
              ),
              data: this.datax,
            };
          }
          if (pivotdef.pivot?.daterange) {
            this.pivotdynamicrows = true;
            pivotdef.pivot['dateAdd'] = this.dateAdd;
            pivotdef.pivot['asEPoch'] = this.asEPoch;
            pivotdef.pivot['self'] = this;
            pivotdef.function = {
              func: this.daterangeindays,
              masterdata: pivotdef.pivot,
            };
          }
          if (pivotdef.pivot?.aftercalcpivotdata) {
            pivotdef.pivot['store'] = this.store;
            pivotdef.pivot['self'] = this;
            pivotdef.aftercalcpivotdata = new Function(
              'data',
              'gridref',
              pivotdef.pivot.aftercalcpivotdata
            );
          }
          if (pivotdef.pivot?.weekday) {
            this.pivotdynamicrows = true;
            //  pivotdef.pivot['weekday'] = this.weekday;
            pivotdef.pivot['self'] = this;
            //  pivotdef.function = {func:this.datetoweekday,masterdata:pivotdef.pivot}
          }
          if (pivotdef.pivot?.weekrange) {
            this.pivotdynamicrows = true;
            pivotdef.pivot['dateAdd'] = this.dateAdd;
            pivotdef.pivot['getNumberOfWeek'] = this.getNumberOfWeek;
            pivotdef.pivot['self'] = this;
            pivotdef.function = {
              func: this.daterangetoweeks,
              masterdata: pivotdef.pivot,
            };
          }
          if (pivotdef.pivot?.monthrange) {
            this.pivotdynamicrows = true;
            pivotdef.pivot['dateAdd'] = this.dateAdd;
            pivotdef.pivot['getNumberOfMonth'] = this.getNumberOfMonth;
            pivotdef.pivot['self'] = this;
            pivotdef.function = {
              func: this.daterangetomonths,
              masterdata: pivotdef.pivot,
            };
          }
          if (pivotdef.pivot?.set) {
            //                         console.log('pivot is set');
            this.pivotdynamicrows = true;
            if (!pivotdef.pivot['set']) {
              pivotdef.pivot['set'] = new Map<string, any>();
            }
            pivotdef.pivot['store'] = this.store;
            pivotdef.pivot['self'] = this;
            pivotdef.function = {
              func: this.loaddataset,
              masterdata: pivotdef.pivot,
            };
          }
          if (pivotdef.pivot?.datafield) {
            this.pivotdynamicrows = true;
            pivotdef.pivot['unique'] = this.createunique;
            pivotdef.function = {
              func: this.calcrowset,
              masterdata: pivotdef.pivot,
            };
          }
        } else {
          this.pivotdynamicrows = true;
        }
      });
    }
  }

  loaddataset(pivotdef, data) {
    if (pivotdef) {
      if (!pivotdef['calculated']) {
        const d = [];
        const params = {};
        if (pivotdef.set.masterfields) {          
          pivotdef.set.masterfields.forEach(item => {
                const control = pivotdef.self.comp.formcontrols.get(item.controlname);                
                params[item.key] = control?control.formcontrol.value:null;
            }
          )
        }
        pivotdef.self.IsCloudService.getDatasync(pivotdef.set.dsname,params).then(
          (piv) => {
            pivotdef['calculated'] = true;
            piv.map((item) => {
              let x = {
                label: item[pivotdef.label],
                value: item[pivotdef.value],
              };
              delete item[pivotdef.label];
              delete item[pivotdef.value];
              if (Object.values(item).length > 0) {
                x[data] = item;
              }
              d.push(x);
            });
          }
        );
        pivotdef.pivot = d;

        return d;
      } else {
        return pivotdef.pivot;
      }
    }
  }

  createunique(data, field, display) {
    const unique = [];
    const keys = [];
    if (Array.isArray(data)) {
      data.map((record) => {
        if (!keys.includes(record[field])) {
          keys.push(record[field]);
          unique.push({ label: record[display], value: record[field] });
        }
      });
    }
    return unique;
  }

  dateAdd(date, interval, units) {
    let ret = new Date(date); //don't change original date
    const checkRollover = function () {
      if (ret.getDate() != date.getDate()) ret.setDate(0);
    };
    if (interval) {
      switch (interval.toLowerCase()) {
        case 'year':
          ret.setFullYear(ret.getFullYear() + units);
          checkRollover();
          break;
        case 'quarter':
          ret.setMonth(ret.getMonth() + 3 * units);
          checkRollover();
          break;
        case 'month':
          ret.setMonth(ret.getMonth() + units);
          checkRollover();
          break;
        case 'week':
          ret.setDate(ret.getDate() + 7 * units);
          break;
        case 'day':
          ret.setDate(ret.getDate() + units);
          break;
        case 'hour':
          ret.setTime(ret.getTime() + units * 3600000);
          break;
        case 'minute':
          ret.setTime(ret.getTime() + units * 60000);
          break;
        case 'second':
          ret.setTime(ret.getTime() + units * 1000);
          break;
        default:
          ret = undefined;
          break;
      }
    }
    return ret;
  }

  getNumberOfWeek(date): number {
    const day = new Date(date);
    const firstDayOfYear = new Date(day.getFullYear(), 0, 1);
    const pastDaysOfYear =
      (day.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return /*day.getFullYear()*100+*/ Math.ceil(
      (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
    );
  }

  getNumberOfMonth(date): number {
    const day = new Date(date);
    return day.getMonth() + 1;
  }

  getDateFormatString() {
    const formatObj = new Intl.DateTimeFormat(
      window.navigator.languages[0]
    ).formatToParts(new Date());

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case 'day':
            return 'dd';
          case 'month':
            return 'mm';
          case 'year':
            return 'yyyy';
          default:
            return obj.value;
        }
      })
      .join('');
  }

  diffdate(from, until) {
    return (
      (new Date(until).getTime() - new Date(from).getTime()) /
      (1000 * 60 * 60 * 24)
    );
  }

  asEPoch(date) {
    const datetmp =
      Math.floor(
        (new Date(date).getTime() - new Date('12/30/1899').getTime()) /
          (1000 * 60 * 60 * 24)
      ) + 1;
    return typeof datetmp !== 'number' ? null : datetmp;
  }

  EPochToDateStr(EPoch) {
    const date = new Date(
      new Date('12/30/1899').getTime() + EPoch * (1000 * 60 * 60 * 24)
    );
    return (
      date.getDate() +
      '-' +
      this.getNumberOfMonth(date) +
      '-' +
      date.getFullYear()
    );
  }

  EndFromStartAndHours(hours: number, start: string): string {
    const strtime = start.split(':');
    const starttime =
      parseInt(strtime[0]) / 24 + parseInt(strtime[1]) / 60 / 24;
    const endtime = (starttime + hours / 24) * 24;
    const end = endtime.toString().split('.');
    return end[0] + ':' + (parseInt(end[1]) * 60).toString().substring(1, 2);
  }

  daterangeindays(pivotdef, data) {
    let days = null;
    if (pivotdef) {
      days = [];
      if (pivotdef.daterange.fromdate) {
        let from = pivotdef.self.comp.formcontrols.get(
          pivotdef.daterange.fromdate
        );
        let until = pivotdef.self.comp.formcontrols.get(
          pivotdef.daterange.untildate
        );
        if (from) {
          from = from.formcontrol.value;
          let d = new Date(from);
          let l = pivotdef.asEPoch(from);
          until = until.formcontrol.value;
          until = pivotdef.asEPoch(until);
          if (l && until) {
            while (l <= until) {
              days.push({ value: l, label: d.toLocaleString().split(' ')[0] });
              l++;
              d = pivotdef.dateAdd(d, 'day', 1);
            }
          }
        }
      }
    }
    return days;
  }

  daterangetoweeks(pivotdef, data) {
    let weeks = null;
    if (pivotdef) {
      weeks = [];
      if (pivotdef.weekrange.fromdate) {
        let from = pivotdef.self.comp.formcontrols.get(
          pivotdef.weekrange.fromdate
        );
        let until = pivotdef.self.comp.formcontrols.get(
          pivotdef.weekrange.untildate
        );
        if (from) {
          from = from.formcontrol.value;
          let d = new Date(from);
          let l = pivotdef.getNumberOfWeek(from);
          until = until.formcontrol.value;
          until = pivotdef.getNumberOfWeek(until);
          while (l <= until) {
            const week = parseInt(d.toLocaleString().split(' ')[2]) * 100 + l;
            weeks.push({ value: week, label: l.toString() });
            l++;
            d = pivotdef.dateAdd(d, 'week', 1);
          }
        }
      }
    }
    return weeks;
  }

  daterangetomonths(pivotdef, data) {
    let months = null;
    if (pivotdef) {
      months = [];
      if (pivotdef.monthrange.fromdate) {
        let from = pivotdef.self.comp.formcontrols.get(
          pivotdef.monthrange.fromdate
        );
        let until = pivotdef.self.comp.formcontrols.get(
          pivotdef.monthrange.untildate
        );
        if (from) {
          from = from.formcontrol.value;
          let d = new Date(from);
          let l = pivotdef.getNumberOfMonth(from);
          until = until.formcontrol.value;
          until = pivotdef.getNumberOfMonth(until);
          while (l <= until) {
            const month = d.getFullYear() * 100 + l;
            months.push({
              value: month,
              label: d.toLocaleString('default', { month: 'long' }),
            });
            d = pivotdef.dateAdd(d, 'month', 1);
            l++;
          }
        }
      }
    }
    return months;
  }

  calcrowset(pivotdef, data) {
    if (data) {
      //console.log('calcrowset', pivotdef.datafield, pivotdef.loopupset, pivotdef.lookupkey, pivotdef.lookupdisplay);
      return pivotdef.unique(data, pivotdef.datafield, pivotdef.lookupdisplay);
    }
  }

  createPivotCols(pivots, bodyCompRef, i, headerCompRef, columnDef?) {
    this.pivot = true;
    if (this.selectedcards) {
      this.selectedcards.clear();
    } else {
      this['selectedcards'] = new Map<string, ComponentRef<Isform2Component>>();
    }
    const column = this.compFactory.resolveComponentFactory(IscolumnComponent);

    bodyCompRef.location.nativeElement.querySelector(
      '.colContainer'
    ).style.display = 'flex';
    if (pivots[i].direction) {
      bodyCompRef.location.nativeElement.querySelector('.colContainer').style[
        'flex-direction'
      ] = pivots[i].direction;
    }

    if (pivots[i].style) {
      if (pivots[i].style.body) {
        Object.keys(pivots[i].style.body).forEach((newStyle) => {
          this.renderer.setStyle(
            bodyCompRef.location.nativeElement,
            `${newStyle}`,
            pivots[i].style.body[newStyle]
          );
        });
      }
    }

    if (columnDef.flex) {
      this.renderer.setStyle(
        bodyCompRef.location.nativeElement,
        'flex',
        columnDef.flex
      );
    }

    const  pivfunc = pivots[i].gridfunction?pivots[i].gridfunction:pivots[i].function;
    if (pivfunc/*&& !pivots[i]['calculated']*/) {
      if (pivfunc.masterdata?.pivot) {
        pivots[i].pivot = pivfunc.masterdata.pivot;
        pivots[i]['calculated'] = true;
      }

      if (pivfunc.func) {

        if (pivfunc?.owner == this.gridref){
          pivots[i].pivot = this.gridref[pivfunc.func](
            pivfunc.masterdata,
            this.datax
          );
        } else
        {
          pivots[i].pivot = pivfunc.func(
            pivfunc.masterdata,
            this.datax
          );
        }

        if (pivots[i].aftercalcpivotdata && !pivots[i]['calculated']) {
          pivots[i].aftercalcpivotdata(pivots[i].pivot, this.gridref);
        }
        pivots[i]['calculated'] = true;
      }
    }

    if (Array.isArray(pivots[i].pivot)) {
      for (const piv of pivots[i].pivot) {
        let v;
        let p;
        if (typeof piv === 'object') {
          v = piv.label;
          p = piv.value?piv.value:piv.val;
        } else {
          p = piv;
        }
        this.totalStack.push(p);
        const pstr = p.toString();
        bodyCompRef[pstr] = (
          bodyCompRef as any
        ).instance.columnContainer.createComponent(column);
        bodyCompRef[pstr].headervisble =
          pivots[i].headervisible != null
            ? pivots[i].headervisible
            : columnDef.headervisible;

        const incoldirection =
          pivots[i].direction == 'column' ? 'row' : 'column';
        const widthincol =
          pivots[i].direction == 'column'
            ? pivots[i].headersize != null
              ? pivots[i].headersize
              : '100px'
            : '100%';
        const heightincol =
          pivots[i].direction == 'column' ? 'inherit' : '30px';
        if (!pivots[i].skipdirection) {
          bodyCompRef[pstr].location.nativeElement.style['flex-direction'] =
            incoldirection;
        }

        bodyCompRef[pstr].instance.columnkey = p;
        bodyCompRef[pstr].instance.keyname = pivots[i].key;
        bodyCompRef[pstr].instance.datapath = pivots[i].datapath;
        bodyCompRef[pstr].instance['items'] = [];
        bodyCompRef[pstr].instance['isparent'] = bodyCompRef.instance;

        bodyCompRef[pstr].changeDetectorRef.detectChanges();

        let headerEl =
          bodyCompRef[pstr].location.nativeElement.querySelector(
            '.pivContainer'
          );
        if (pivots[i].headercomp == 'none') {
          this.renderer.removeChild(
            bodyCompRef[pstr].location.nativeElement,
            headerEl
          );
          headerEl = null;
        } else if (headerEl) {
          headerEl.style.flex = 1;

          if (pivots[i].style) {
            if (pivots[i].style.pivContainer) {
              Object.keys(pivots[i].style.pivContainer).forEach((newStyle) => {
                this.renderer.setStyle(
                  headerEl,
                  `${newStyle}`,
                  pivots[i].style.pivContainer[newStyle]
                );
              });
            }
          }
        }

        // if (pivots[i].direction == columnDef.renderdirection) {
        if (bodyCompRef[pstr].headervisble) {
          if (bodyCompRef[pstr].instance.pivotContainer) {
            const celltype = pivots[i].headercomp
              ? pivots[i].headercomp
              : 'iscel';
            const cel = this.compFactory.resolveComponentFactory(
              this.registry.getWidgetType(celltype)
            );
            const ref = bodyCompRef[
              pstr
            ].instance.pivotContainer.createComponent(cel, 0);
            ref.instance.label = v ? v : p;
            const identclass = pivots[i].headerclass
              ? pivots[i].headerclass
              : 'pivot-ident';

            if (identclass == 'pivot-ident') {
              ref.location.nativeElement.style.height = pivots[i].height
                ? pivots[i].height
                : heightincol;
              ref.location.nativeElement.style.width = pivots[i].width
                ? pivots[i].width
                : widthincol;
              //ref.location.nativeElement.style['border-right'] = 'solid 1px grey';
            }
            pivots[i].headerCompref = ref;
            pivots[i].columnCompref = bodyCompRef[pstr];
            pivots[i].parentCompref = bodyCompRef;

            this.renderer.addClass(ref.location.nativeElement, identclass);
            if (pivots[i].onclick) {
              ref.location.nativeElement.addEventListener('click', {
                handleEvent: (ev) => {
                  pivots[i].onclick.func(ev, piv, this, pivots, i);
                },
              });
            }
            if (pivots[i].oncontextmenu) {
              ref.location.nativeElement.addEventListener('contextmenu', {
                handleEvent: (ev) => {
                  pivots[i].oncontextmenu.func(ev, piv, this, pivots, i);
                },
              });
            }
          }
        }

        const bodyEl =
          bodyCompRef[pstr].location.nativeElement.querySelector(
            '.colContainer'
          );
        this.gridEvents(bodyEl, this);
        if (pivots[i].bodycomp == 'none') {
          this.renderer.removeChild(
            bodyCompRef[pstr].location.nativeElement,
            bodyEl
          );
        } else {
          if (bodyEl) {
            if (pivots[i].style) {
              if (pivots[i].style.colContainer) {
                Object.keys(pivots[i].style.colContainer).forEach(
                  (newStyle) => {
                    this.renderer.setStyle(
                      bodyEl,
                      `${newStyle}`,
                      pivots[i].style.colContainer[newStyle]
                    );
                    if (
                      typeof pivots[i].style.colContainer[newStyle] == 'string'
                    ) {
                      if (
                        pivots[i].style.colContainer[newStyle].includes(
                          '$numberofrows'
                        )
                      ) {
                        if (!columnDef['recalcstyle']) {
                          columnDef['recalcstyle'] = {};
                        }
                        columnDef['recalcstyle'][newStyle] =
                          pivots[i].style.colContainer[newStyle];
                        if (!columnDef[pstr]) {
                          columnDef[pstr] = {};
                        }
                        columnDef[pstr]['bodyel'] = bodyEl;
                      }
                    }
                  }
                );
              }
            }
          }
        }

        const footerEl =
          bodyCompRef[p.toString()].location.nativeElement.querySelector(
            '.totContainer'
          );
        if (pivots[i].total && pivots[i].totaldisplay !== 'hidden') {
          if (footerEl) {
            if (!pivots[i].totalclass) {
              footerEl.style.flex = 1;
            }
          }
          if (bodyCompRef[p].instance.totalContainer) {
            const celltype = pivots[i].footercomp
              ? pivots[i].footercomp
              : 'iscel';
            const celfooter =
              celltype != 'none'
                ? this.compFactory.resolveComponentFactory(
                    this.registry.getWidgetType(celltype)
                  )
                : null;

            if (celfooter) {
              let cumulators;
              if (pivots[i].accumulators) {
                cumulators = pivots[i].accumulators;
                this.renderer.setStyle(footerEl, 'display', 'flex');
              } else {
                cumulators = [
                  {
                    expression: 'sum',
                    field: pivots[i].total,
                    label: 'total : ',
                  },
                ];
              }

              cumulators.forEach((cumulator) => {
                const ref = bodyCompRef[
                  pstr
                ].instance.totalContainer.createComponent(celfooter, 0);
                bodyCompRef[pstr]['tot' + cumulator.field] = ref;
                let skey = '';
                let sep = '';
                for (const p of this.totalStack) {
                  skey = skey + sep + p;
                  sep = ';';
                }
                const cellclass = pivots[i].cellclass
                  ? pivots[i].cellclass
                  : 'pivot-cell';
                const totalclass = pivots[i].totalclass
                  ? pivots[i].totalclass
                  : 'pivot-total';

                ref.instance.label = '';
                //ref.totalkey = skey + ';tothours';
                ref.totalkey = skey + ';tot' + cumulator.field;
                ref.totallabel = cumulator.label;
                this.renderer.addClass(ref.location.nativeElement, totalclass);

                ref.location.nativeElement.style.height = heightincol;
                ref.location.nativeElement.style.width = widthincol;
                // ref.location.nativeElement.style['border-right'] = 'solid 1px grey';

                if (pivots[i].style) {
                  if (pivots[i].style.totalContainer) {
                    Object.keys(pivots[i].style.totalContainer).forEach(
                      (newStyle) => {
                        this.renderer.setStyle(
                          ref.location.nativeElement,
                          `${newStyle}`,
                          pivots[i].style.totalContainer[newStyle]
                        );
                      }
                    );
                  }
                }
              });
            } else {
              this.renderer.removeChild(
                bodyCompRef[pstr].location.nativeElement,
                footerEl
              );
            }
          }
        } else {
          this.renderer.removeChild(
            bodyCompRef[pstr].location.nativeElement,
            footerEl
          );
        }

        bodyCompRef[p].changeDetectorRef.detectChanges();

        if (i < pivots.length - 1) {
          this.createPivotCols(
            pivots,
            bodyCompRef[pstr],
            i + 1,
            headerCompRef,
            p
          );
        } else {
          if (pivots[i].style) {
            if (pivots[i].style.body) {
              Object.keys(pivots[i].style.body).forEach((newStyle) => {
                this.renderer.setStyle(
                  bodyCompRef[pstr].location.nativeElement,
                  `${newStyle}`,
                  pivots[i].style.body[newStyle]
                );
              });
            } else {
              bodyCompRef[pstr].location.nativeElement.style['border-bottom'] =
                '1px solid grey';
              bodyCompRef[pstr].location.nativeElement.style['border-right'] =
                '1px solid grey';
            }
          } else {
            bodyCompRef[pstr].location.nativeElement.style['border-bottom'] =
              '1px solid grey';
            bodyCompRef[pstr].location.nativeElement.style['border-right'] =
              '1px solid grey';
          }
        }
        this.totalStack.pop();
      }
    }
  }

  createformfields(fielddefs) {
    const newfields = [];

    if (fielddefs) {
      console.log('create form fields : ',this.gridref.id);
      fielddefs.forEach((field) => {
        const formcontrolname = this.gridid.toString() + '.' + field.name;
        const control = this.comp.formcontrols.get(formcontrolname);
        const frmctrl = control ? control.formcontrol : new FormControl('');
        const master = [];
        master.push(this.gridid);

        this.gridFormGroup.addControl(field.name, frmctrl);

        this.comp.formcontrols.set(formcontrolname, {
          name: field.name,
          formcontrol: frmctrl,
          master: master,
          in_use: true,
        });
        newfields.push(field);
      });

      const f = new Function(
        'control',
        "const straat = control.get('straat').value;const plaats = control.get('plaats').value;return straat==plaats? {PlaatsEnStraat:{errormessage:'Plaats en Straat zijn gelijk aan elkaar'}}:null"
      );

      const straatNotEqualsPlaats = (control: AbstractControl) => {
        return f(control);
      };

      // const straatNotEqualsPlaats:ValidatorFn = (control:AbstractControl):ValidationErrors| null => {
      //   const straat = control.get('straat').value;
      //   const plaats = control.get('plaats').value;

      //   return straat==plaats? {PlaatsEnStraat:{errormessage:"Plaats en Straat zijn gelijk aan elkaar"}}:null
      // }

      (<unknown>this.gridFormGroup?.get('nummer') as FormControl)?.setValidators([
        Validators.required,
      ]);

      //if(Object.keys(this.gridFormGroup.value).includes('straat')){
      //  this.gridFormGroup.setValidators(straatNotEqualsPlaats);
      //}
      //this.gridFormGroup?.updateValueAndValidity();
    }
    if (this.comp.widgets.get(this.gridid)) {
      if (this.gridcomp.formControlFielddefs) {
        this.gridcomp.formControlFielddefs.setValue(newfields);
        this.gridcomp.formControlFielddefs['master'] = true;
      }
    }
    return newfields;
  }

  updateformfields(datadef) {
    const newitem = [],
      keep = [],
      remove = [];
    this.fielddefs.map((item) => {
      if (
        datadef.findIndex((def) => {
          return item.name == def.name;
        }) != -1
      ) {
        keep.push(item);
      } else {
        remove.push(item);
      }
    });
    datadef.map((def) => {
      if (
        keep.findIndex((keepitem) => {
          keepitem.name == def.name;
        }) == -1
      ) {
        newitem.push(def);
      }
    });
    this.createformfields(newitem);
    this.fielddefs = datadef;
  }

  removeformfields(remove, all) {
    if (remove) {
      remove.map((item) => {
        let control = this.comp.formcontrols.get(item.name);
        this.comp.formcontrols.delete(item.name);
        control = null;
      });
    } else if (all) {
      if (this.fielddefs)
        this.fielddefs.map((item) => {
          let control = this.comp.formcontrols.get(item.name);
          this.comp.formcontrols.delete(item.name);
          control = null;
        });
    }
  }

  clearColumn(key: string) {
    // debugger;
    // while (this.bodyCompRef[key].instance.columnContainer.length > 0) {
    //   this.bodyCompRef[key].instance.columnContainer.remove(0);
    // }

    this.bodyCompRef[key]?.instance.columnContainer.clear();
    //debugger;
  }

  setCustomFilter(headercomp, key) {
    let filtercomp;

    filtercomp = this.compFactory.resolveComponentFactory(
      this.registry.getWidgetType('iscustomfilter')
    );
    filtercomp = (
      headercomp.instance as Isheadercolumn1Component
    ).customfilter.createComponent(filtercomp);
    filtercomp.changeDetectorRef.detectChanges();
    filtercomp.instance.filter.formControlFielddefs.setValue([{ name: key }]);
    this.expressionFilters.push(filtercomp.instance.filter);
    filtercomp.instance.filter.formControlExpression.valueChanges.subscribe(
      (exp) => {
        this.expFilter = this.expFilter.filter(
          (ex) => this.getExpressionField(ex) != key
        );
        if (exp.expression) this.expFilter.push(exp.expression);
        this.execExpressionFilter();
        this.gridref.filterpanel.expression = this.updateFilterPanel();
        if (!this.filterpanelSubscription) {
          this.filterpanelSubscription =
            this.gridref.filterpanel.expressionCleared.subscribe((cleared) => {
              if (cleared == true) {
                this.clearFilters();
              }
            });
        }
        this.gridref.filterpanel.cd.detectChanges();
      }
    );
    headercomp.instance['filtercomp'] = filtercomp;
  }

  setSticky(sticky: number, c: ComponentRef<any>, color: string) {
    c.location.nativeElement.style.position = 'sticky';
    c.location.nativeElement.style.background = color;
    c.location.nativeElement.style['z-index'] = 100;
    if (sticky >= 0) {
      c.location.nativeElement.style.left = sticky.toString() + 'px';
    } else {
      c.location.nativeElement.style.right = (0 - sticky).toString() + 'px';
    }
  }

  setHeaderComp(key, colidx) {
    const headercompFact = this.compFactory.resolveComponentFactory(
      this.registry.getWidgetType(this.columns[key].headercomp)
    );
    const headercomp = this.headerContainer.createComponent(headercompFact);
    this.columns[key].sticky
      ? this.setSticky(this.columns[key].sticky, headercomp, 'var(--500)')
      : null;
    this.columns[key].customfilter
      ? this.setCustomFilter(headercomp, key)
      : null;

    if (!this.pivots) {
      //headercomp.location.nativeElement.style.width = this.columns[key].width;
    }
    this.headerCompRef[key] = headercomp;
    (headercomp.instance as any).headervisible =
      this.columns[key].headervisible;
    (headercomp.instance as any).visible = this.columns[key].headervisible;
    (headercomp.instance as any).label = this.columns[key].label;

    // check for translations    
    if (headercomp.instance['translate']) {
      (headercomp.instance as any).translation = this.columns[key].translation;
      const { lang } = this.route.snapshot.queryParams;
      headercomp.instance['translate'](lang ? lang : 'nl');
    }

    (headercomp.instance as any).colid = key;
    (headercomp.instance as any).colindex = colidx;
    (headercomp.instance as any).gridid = this.gridid;
    if (this.columns[key].pivots) {
      (headercomp.instance as any).subheadervisible = true;
    }

    if (this.columns[key].headerclass) {
      headercomp.location.nativeElement.classList.add(
        'grid-' + this.gridref.id + '-' + this.columns[key].headerclass
      );
    }
    if (
      !(
        (this.columnKeys[0] == key || this.columnKeys[1] == key) &&
        this.selectCheckboxColumn
      )
    ) {
      this.headerResize(headercomp);
    }
    headercomp.changeDetectorRef.detectChanges();
    return headercomp;
  }

  setFooterComp(key, colidx) {
    const celltype = 'iscel';
    const footercompFact = this.compFactory.resolveComponentFactory(
      this.registry.getWidgetType(celltype)
    );
    const footercomp = this.footerContainer.createComponent(footercompFact);
    this.columns[key].sticky
      ? this.setSticky(this.columns[key].sticky, footercomp, 'var(--500)')
      : null;
    //this.columns[key].customfilter ? this.setCustomFilter(footercomp, key) : null;

    //if (!this.pivots) {
    //headercomp.location.nativeElement.style.width = this.columns[key].width;
    //}
    this.footerCompRef[key] = footercomp;
    (footercomp.instance as any).visible = this.columns[key].footervisible;
    if ((footercomp.instance as any).visible) {
      switch (this.columns[key].footercomp) {
        case 'reducer':
          {
            const reducer = this.gridref.reducers?.find((item) => {
              return this.columns[key].reducer == item.name;
            });
            if (reducer) {
              (footercomp.instance as any).label = reducer.defaultvalue;
            }
          }
          break;
        case 'label':
          (footercomp.instance as any).label = this.columns[key].footertext;
          break;
        case 'collabel':
          (footercomp.instance as any).label = this.columns[key].label;
          break;
        default:
          (footercomp.instance as any).label = null;
      }
    }
    (footercomp.instance as any).colid = key;
    (footercomp.instance as any).colindex = colidx;
    (footercomp.instance as any).gridid = this.gridid;

    if (this.columns[key].footerclass) {
      footercomp.location.nativeElement.classList.add(
        'grid-' + this.gridref.id + '-' + this.columns[key].footerclass
      );
    }
    //if (!((this.columnKeys[0] == key || this.columnKeys[1] == key) && this.selectCheckboxColumn)) {
    //  this.headerResize(footercomp);
    //}
    footercomp.changeDetectorRef.detectChanges();
    return footercomp;
  }

  getColumnTemplate() {
    let s = '';
    for (const [colidx, key] of this.columnKeys.entries()) {
      if (this.columns[key] && !this.columns[key].hidden) {
        s = s + ' ' + this.columns[key].width.toString();
      }
    }
    return s;
  }

  setColumnKeys(info) {
    this.columnKeys = [];
    for (const col of info.columns) {
      //if(typeof col !=='object'){
      const res = this.defineColumn(col);
      this.columns[res.name] = res;
      this.columnKeys.push(res.name);
      //}
    }
  }

  getColumnKeys(info) {
    this.columnKeys = [];
    if (info?.columns) {
      for (const col of info.columns) {
        //if(typeof col !=='object'){
        this.columnKeys.push(col.name);
        //}
      }
    }
  }

  createColumns(info) {
    if (this.fielddefs == null) {
      this.fielddefs = info.fielddefs
        ? info.fielddefs
        : this.createformfields(info.columns);
    }
    this.totalStack = [];
    this.definefunctions();
    if (this.selectCheckboxColumn) {
      info.columns = [
        {
          name: 'select',
          label: '',
          headercomp: 'isheaderselect',
          headervisible:
            this.selectHeadervisible != null ? this.selectHeadervisible : false,
          bodycomp: 'ischeckboxcel',
          width: '50px',
          bodycompconfig: { static: [], data: 'checkedVal', selection: true },
        },
        ...info.columns,
      ];
    }

    if (info.rowfunctions) {
      this.rowfunctions = this.definerowFunctions({
        functions: info.rowfunctions,
      });
    } else {
      this.rowfunctions = { functions: [] };
    }

    if (info.columnkey) {
      this.columnkeyfunction = this.definecolumnkeyFunction(info.columnkey);
    } else {
      this.columnkeyfunction = null;
    }

    this.columns = {};

    if (!this.pivots) {
      if (this.editmode) {
        if (this.autoprimary) {
          this.primarykeys = [info.columns[0].name];
        }
      }
      this.setColumnKeys(info);
    }

    let s = this.getColumnTemplate();
    this.gridref.el.nativeElement.style.setProperty('--columnwidth', s);
    this.gridref.cd.detectChanges();

    const el = this.gridref.el.nativeElement;

    let gridwidth = el.getBoundingClientRect().width;

    this.isheader = this.gridref.el.nativeElement.querySelector('isheader');
    this.isbody = this.gridref.el.nativeElement.querySelector('isbody');
    this.isfooter = this.gridref.el.nativeElement.querySelector('isfooter');

    //this.isheader.setAttribute('style', 'grid-template-columns', s);
    //this.isbody.setAttribute('style', 'grid-template-columns', s);

    if (this.headerContainer.length == 0) {
      for (const [colidx, key] of this.columnKeys.entries()) {
        if (this.columns[key]) {
          if (gridwidth >= 0) {
            this.columnsInView.push(key);
          }

          if (this.columns[key].selection) {
            const control = new FormControl([]);
            this.comp.formcontrols.set(
              this.gridid.toString() + '.' + 'select',
              {
                name: this.columns[key].name,
                formcontrol: control,
              }
            );
          }

          if (!this.columns[key].hidden) {
            const headercomp =
              this.columns[key].headercomp !== 'none'
                ? this.setHeaderComp(key, colidx)
                : null;
            if (this.gridref.footervisible) {
              const footercomp =
                this.columns[key].footercomp !== 'none'
                  ? this.setFooterComp(key, colidx)
                  : null;
            }

            const bodycomp =
              this.compFactory.resolveComponentFactory(IscolumnComponent);
            const columnRef = this.bodyContainer.createComponent(bodycomp);
            columnRef['disablerowselected'] =
              this.columns[key].disablerowselected;

            //columnRef.location.nativeElement.style.width = this.columns[key].width;

            this.columns[key].sticky
              ? this.setSticky(
                  this.columns[key].sticky,
                  columnRef,
                  this.columns[key].stickycolor ?? 'white'
                )
              : null;

            gridwidth -=
              columnRef.location.nativeElement.getBoundingClientRect().width;

            if (this.columns[key].renderdirection) {
              const colel =
                columnRef.location.nativeElement.querySelector('.colContainer');
              colel.style.display = 'flex';
              colel.style['flex-direction'] = this.columns[key].renderdirection;
              if (this.columns[key].renderdirection == 'column') {
                const h = this.isbody.getBoundingClientRect().height;
                if (h > 0) {
                  colel.style['height'] = h;
                }
                //colel.style['height'] = columnRef.location.nativeElement.getBoundingClientRect().height;
              }
            }

            if (this.columns[key].columnclass) {
              columnRef.location.nativeElement
                .querySelector('.colContainer')
                .classList.add(
                  'grid-' +
                    this.gridref.id +
                    '-' +
                    this.columns[key].columnclass
                );
            }

            this.bodyCompRef[key] = columnRef;
            (columnRef.instance as any).columnkey = key;
            (columnRef.instance as any).keyname = key;

            columnRef.changeDetectorRef.detectChanges();

            if (this.columns[key].pivots) {
              const total = [];
              let teller = 0;

              for (const pivot of this.columns[key].pivots) {
                if (pivot.direction == this.columns[key].renderdirection) {
                  if (headercomp) {
                    const hcmp = this.compFactory.resolveComponentFactory(
                      this.registry.getWidgetType('islabel')
                    );
                    const headersub = (
                      headercomp as any
                    ).instance.subheader.createComponent(hcmp);
                    headersub.instance.label = pivot.key;
                    headersub.location.nativeElement.style.width = '100px';
                  }

                  if (pivot.total) {
                    total[teller++] = pivot;
                  }
                } else {
                  if (typeof pivot.pivot !== 'object') {
                    for (const p of pivot.pivot) {
                      const header = (
                        headercomp as any
                      ).instance.subheader.createComponent(headercomp);
                      header.instance.label = p;
                    }
                  }
                }
              }

              if (headercomp) {
                if (total.length > 0) {
                  for (let i = total.length - 1; i >= 0; i--) {
                    const hcmp = this.compFactory.resolveComponentFactory(
                      this.registry.getWidgetType('islabel')
                    );
                    const header = (
                      headercomp as any
                    ).instance.subheader.createComponent(hcmp);
                    header.instance.label = 'tot ' + total[i].key;
                    header.location.nativeElement.style.width = '100px';
                  }
                }
              }

              //if (!this.outlet.indesigner) {
              this.createPivotCols(
                this.columns[key].pivots,
                this.bodyCompRef[key],
                0,
                this.headerCompRef[key],
                this.columns[key]
              );
            }
          }
        }
      }

      this.setcontrolid();
      //this.gridFormGroup.setValidators([testVal])

      //let height = this.bodyCompRef[0].location.nativeElement.getBoundingClientRect().height
      //this.gridHeight = height>0?height:300

      //this.scrollbar = this.bodyCompRef[0].location.nativeElement.parentElement.querySelector('.fakeScroll')
      //let barHeight = (this.gridHeight*(this.numberOfRows/this.data.length))

      //this.scrollbar.style.height = (barHeight>20)? barHeight.toString()+'px': '20px'
      //this.makeDraggable()
    }
    this.gridLoaded.next(true);
  }

  updateFilterPanel() {
    const expressions = [];
    this.expFilter.forEach((ex) => {
      expressions.push(this.expressionFromJson(ex));
    });
    let expressionString = '';
    expressions.forEach((expression) => {
      expressionString = expressionString + '(' + expression + ')' + ' AND ';
    });
    expressionString = expressionString.substr(0, expressionString.length - 5);
    return expressionString;
  }

  clearFilters() {
    this.expressionFilters.forEach((filter) => {
      filter.form.get('expression').setValue(null);
      filter.cd.detectChanges();
    });
    this.gridref.filterpanel.expression = null;
    this.expFilter = [];
    this.execExpressionFilter();
  }

  expressionFromJson(json) {
    if (json) {
      if (this.isLeaf(json)) {
        const left = json['field'] ? json['field'] : ' ';
        const operator = json['operator'] ? json['operator'] : ' ';
        const right = json['value'] ? json['value'] : ' ';
        return left + ' ' + operator + ' ' + right;
      } else {
        let left = this.expressionFromJson(json['leftExpression']);
        left = left ? left : ' ';
        const operator = json['operator'] ? json['operator'] : ' ';
        let right = this.expressionFromJson(json['rightExpression']);
        right = right ? right : ' ';
        return '(' + left + ' ' + operator + ' ' + right + ')';
      }
    }
  }

  getExpressionField(exp) {
    if (this.isLeaf(exp)) {
      return exp['field'];
    } else {
      return this.getExpressionField(exp['leftExpression']);
    }
  }

  setStylefunctions(celRef, funcobj, data) {
    if (!this.isInDesigner) {
      if (Array.isArray(funcobj)) {
        for (const f of funcobj) {
          let l = null;

          if (f.qrysel) {
            l = celRef.location.nativeElement.querySelectorAll(f.qrysel);
          } else {
            l = [celRef.location.nativeElement];
          }
          if (l) {
            const res = f.func(data, this, celRef.instance);
            l.forEach((el) => {
              if (res?.includes('!important')) {
                el.style.setProperty(
                  f.styleprop,
                  res.substring(0, res.length - 11),
                  'important'
                );
              } else {
                el.style.setProperty(f.styleprop, res);
              }
            });
          }
        }
      }
    }
  }

  sum(a: Array<any>, reducer: any) {
    let acc;
    if (a) {
      acc = a.reduce(function (total, item) {
        return total + item[reducer.field];
      }, reducer.startvalue);
      return acc.toFixed(reducer.decimals);
    }
    return null;
  }

  subtract(a: Array<any>, reducer: any) {
    let acc;
    if (a) {
      const acc = a.reduce(function (total, item) {
        return total - item[reducer.field];
      }, reducer.startvalue);
      return acc.toFixed(reducer.decimals);
    }
    return null;
  }

  average(a: Array<any>, reducer: any) {
    if (a) {
      const acc = a.reduce(
        function (prev, item) {
          return { tot: prev.tot + item[reducer.field], count: prev.count++ };
        },
        { tot: reducer.startvalue, count: 0 }
      );
      if (acc.count > 0) {
        return (acc.tot / acc.count).toFixed(reducer.decimals);
      } else {
        return (0.0).toFixed(reducer.decimals);
      }
    }
    return null;
  }

  min(a: Array<any>, reducer: any) {
    let acc;
    if (a) {
      const acc = a.reduce(function (total, item) {
        return item[reducer.field] < total ? item[reducer.field] : total;
      }, reducer.startvalue);
      return acc.toFixed(reducer.decimals);
    }
    return null;
  }

  max(a: Array<any>, reducer: any) {
    let acc;
    if (a) {
      const acc = a.reduce(function (total, item) {
        return item[reducer.field] > total ? item[reducer.field] : total;
      }, reducer.startvalue);
      return acc.toFixed(reducer.decimals);
    }
    return null;
  }

  accumulator(pivots, a: Array<any>, field?: string) {
    let acc;
    if (a) {
      acc = a.reduce(function (total, item) {
        if (field) {
          return total + item[field];
        } else {
          return total + item[pivots[0].total];
        }
      }, 0);
    }
    return acc;
  }

  reOrderdata(data, pivots) {
    if (Array.isArray(pivots[0].pivot)) {
      const d = {};
      for (const piv of pivots[0].pivot) {
        let p = piv;
        let v;
        if (typeof p === 'object') {
          p = piv.value?piv.value:piv.val;
          v = piv.label;
        }
        if (pivots.length == 1) {
          if (Array.isArray(data)) {
            const x = data.filter((d) => {              
              if (typeof this.gridref[pivots[0].key] === 'function') {
                return this.gridref[pivots[0].key](d) == p
              } else { 
                return d[pivots[0].key] == p;
              }
            });
            d[p] = x;
            if (pivots[0].accumulators) {
              pivots[0].accumulators.forEach((accumulator) => {
                d[p + ';tot' + accumulator.field] = this.accumulator(
                  pivots,
                  x,
                  accumulator.field
                );
              });
            } else {
              d[p + ';tot' + pivots[0].total.toString()] = this.accumulator(
                pivots,
                x
              );
            }
          }
        } else {
          const temp = this.reOrderdata(
            data.filter((d) => {
              return d[pivots[0].key] == p;
            }),
            pivots.slice(1)
          );

          const toreduce = [];
          for (const [key, value] of Object.entries(temp)) {
            if (key.includes(pivots[0].total)) {
              toreduce.push(value);
            }
            //if((value as Array<any>).length>0){
            d[p + ';' + key] = value;
            if ((value as Array<any>).length > 0) {
              // d[p+";"+key]=som;
            }
          }
          d[p + ';tot' + pivots[0].total.toString()] = toreduce.reduce(
            function (total, item) {
              return total + item;
            },
            0
          );

          //==this.accumulator(pivots,Object.values(temp))
        }
      }
      return d;
    } else {
      return data;
    }
  }

  hoovercard(data, id) {
    const card = this.renderCard(data, id, this, id);
  }

  editcard(data, id) {
    const card = this.renderCard(data, id, this, id);
  }

  roundTo(n, digits) {
    if (digits === undefined) {
      digits = 0;
    }

    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    const test = Math.round(n) / multiplicator;
    return +test.toFixed(digits);
  }

  setcontrolid() {
    if (!this.columns) return;
    this.comp.formcontrols.forEach((fc, key) => {
      if (Array.isArray(fc.master)) {
        if (fc.master ? fc.master.includes(this.gridid) : false) {
          const col = this.columns[fc.name];
          if (col) {
            col.controlid = fc.master[0];
          }
        }
      }
    });
  }

  seteventsoncomponent(component) {
    if (component.events) {
      const event = produce(component.events, (draft) => {
        for (const ev of draft) {
          ev.parentid = component.id;
          const func = new Function(ev.config.code);
          const eventmap = new EventMap(
            ev.name,
            ev.config.type,
            func,
            ev.id,
            this.overlay,
            this.registry,
            ev.parentid,
            ev.config.specific,
            ev.config.selectall,
            this.comp,
            this.store
          );
          this.comp.setEvent(component.id, eventmap, ev.config.ref);
        }
      });
    }
    if (component.children) {
      component.children.map((child) => {
        if (component.events) {
          this.seteventsoncomponent(child);
        }
      });
    }
  }

  renderFunction(a, compid) {
    const args: string[] = [];
    if (a.attribs) {
      a.attribs = JSON.parse(a.attribs);
      if (a.attribs.args) {
        a.attribs.args.map((param) => {
          args.push(param);
        });
      }
    }

    let func;
    try {
      //a.config = JSON.parse(a.config.code);
      if (a.config.params) {
        args.push(a.config.params);
      }
      args.push(a.config.code);
      func = new Function(...args);
      let funcs = this.comp.functions.get(compid);
      if (!funcs) {
        funcs = new Map<string, Function>();
      }
      funcs.set(a.name, func);

      //this.compService.funcnames.set(a.name,funcs);
      //this.compService.functions.set(a.parentid,funcs);

      this.comp.functions.set(compid, funcs);
    } catch (err) {
      console.error({
        item: 'function ' + a.name,
        message: err,
        code: a.config.code,
        args: a.attribs,
      });
    }
  }

  addAttribute(cmpRef) {
    cmpRef?.location?.nativeElement.setAttribute(
      'orgid',
      cmpRef?.instance?.templateid
    );

    for (const c of cmpRef?.ischildren ? cmpRef.ischildren : []) {
      this.addAttribute(c);
    }
  }

  clearCardbuffer()
  {
    this.cardBuffer.length = 0;
  }

  async buffered(data, id, self, compid) {
    let cmpRef = null;
     if (this.cardBuffer?.length>0){
       cmpRef = this.cardBuffer?.find((item) => 
       {
        const key1 = item.instance.data[this.gridref.keyfield];
        const key2 = data[this.gridref.keyfield];
        return key1 == key2
       }
       );
     } 
    if (!cmpRef) {
      //cmpRef = await this.renderCardunbufferd(data, id, self, compid)
      this.cardBuffer.push(cmpRef);
      return cmpRef
    } else
    {
      return cmpRef
    }
  }  

  async renderCard(data, id, self, compid) {
      const cmpRef = await (
        await this.outlet.copyComponent(id, compid, 0, null, data)
      ).renderedtree;

      if (!cmpRef){
        console.log('Render card : ',id,' not succesfull');
      }
      if (cmpRef){
        this.addAttribute(cmpRef);

        const cardClickHandler = {
          handleEvent: (ev) => {
            const cmpRef = this.comp.gc(Number(ev.currentTarget.getAttribute('_isid')));
            self.triggerDetail(data, cmpRef, ev);
          },
        };

        cmpRef.location.nativeElement.addEventListener('click', cardClickHandler);
        cmpRef.instance.isparent = this.gridref;

        data['rendered'] = true;
    
        return cmpRef;
      } else 
      {
        return null;
      }        
  }

  async addCardIngrid(data, formid, trackby, key) {
    const columnDef = this.columns[key[0]];
    let bodyCompRef = this.bodyCompRef;
    for (const k of key) {
      bodyCompRef = bodyCompRef[k];
    }
    const cardCompRef = await this.renderCard(data, formid, this, trackby);
    this.cardlist.push(cardCompRef);
    cardCompRef.instance['columnkey'] = key;
    cardCompRef['columnDef'] = columnDef;
    cardCompRef['bodyCompRef'] = bodyCompRef;
    if (bodyCompRef.instance['items']) {
      bodyCompRef.instance['items'].push(cardCompRef.instance.data);
    }
    bodyCompRef.instance.columnContainer.insert(cardCompRef.hostView);
    const i = 0;
    cardCompRef.location.nativeElement.setAttribute(
      'istag',
      'row' + (i == 0 ? '-selected' : '')
    );
    cardCompRef['containerRef'] = bodyCompRef.instance.columnContainer;

    if (i == 0) {      
      if(this.userowselect)cardCompRef.location.nativeElement.classList.add('row-selected');
    } else {
      if (columnDef.rowclass) {
        cardCompRef.location.nativeElement.classList.add(columnDef.rowclass);
      }
    }

    if (!(this.columnKeys[0] == key && this.selectCheckboxColumn)) {
      this.focusAble(cardCompRef, this.headerCompRef[key[0]]);
    } else {
      this.selectAble(cardCompRef, this.headerCompRef[key[0]]);
    }

    this.setStylefunctions(cardCompRef, this.rowfunctions.functions, data);
    this.setStylefunctions(cardCompRef, columnDef.functions, data);
    this.calcPivotpart(key[0], key[1]);
    cardCompRef.changeDetectorRef.detectChanges();
    cardCompRef.location.nativeElement.click();
  }

  async fillData1(key, columnDef, data) {
    if (!data) {
      return;
    }
    let bodyCompRef = this.bodyCompRef;
    let celCompRef = this.celCompRef;

    this.gridHeight = 2000;

    for (const k of key) {
      bodyCompRef = bodyCompRef[k];

      if (!celCompRef[k]) {
        celCompRef[k] = {};
      }
      celCompRef = celCompRef[k];
    }
    let numberofrows = this.numberOfRows;
    numberofrows = !this.virtualscroll
      ? data.length
      : numberofrows > data.length
      ? data.length
      : numberofrows;

    //if(columnDef.name=='employeenr'){
    //    return
    //}

    if (columnDef.pivots) {
      //   console.log('enter');
      //console.log(`%cClass: IsGridService, Function:loadData(l): reorderData`, 'color: cyaan;', data);

      columnDef.pivotData = this.reOrderdata(data, columnDef.pivots);
      //console.log('pivotdata : ', columnDef.pivotData);
      //  console.log('exit');
      //let keys = columnDef.pivots.map(p => p.key)

      for (const [k, value] of Object.entries(columnDef.pivotData)) {
        //if ((value as Array<any>).length>0){
        const keys = k.split(';');
        const colDef = columnDef;
        const oldPivot = colDef.pivots;
        colDef.pivots = null;

        await this.fillData1([...key, ...keys], colDef, value);
        colDef.pivots = oldPivot;
        //}
      }
      return;
    }

    if (columnDef.cellform) {
      if (numberofrows) {
        if (columnDef.recalcstyle) {
          console.log('columnDef.recalcwidth ', columnDef.recalcstyle);
          Object.keys(columnDef.recalcstyle).forEach((newStyle) => {
            console.log('recalcstyle : ', columnDef.recalcstyle[newStyle]);
            //const templatestr = columnDef.recalcstyle[newStyle].replace('$numberofrows', numberofrows.toString());
            const w = columnDef.columnwidth(
              numberofrows,
              columnDef[key[1]].bodyel.offsetHeight
            );
            //const w = (Math.trunc(numberofrows / Math.trunc(columnDef[key[1]].bodyel.offsetHeight / 100)) + 1) * 251;
            const templatestr = w.toString() + 'px';
            //this.renderer.setStyle(columnDef.bodyel, `${newStyle}`, templatestr);
            this.renderer.setStyle(
              columnDef[key[1]].bodyel,
              'width',
              templatestr
            );
          });
        }
        for (let i = 0; i < numberofrows; i++) {
          let cellform;
          let trackby;

          if (typeof columnDef.cellform === 'object') {
            const key = data[i][columnDef.formkey];
            cellform = columnDef.cellform[key].form;
            trackby = columnDef.cellform[key].trackby;
          } else {
            cellform = columnDef.cellform;
            trackby = columnDef.trackby;
          }

          const cardCompRef = await  this.renderCard(
            data[i],
            cellform,
            this,
            null
          );

          if (cardCompRef){
          this.cardlist.push(cardCompRef);
          cardCompRef.instance['columnkey'] = key;
          cardCompRef['columnDef'] = columnDef;
          cardCompRef['bodyCompRef'] = bodyCompRef;
          if (bodyCompRef.instance['items']) {
            bodyCompRef.instance['items'].push(cardCompRef.instance.data);
          }
          bodyCompRef.instance.columnContainer.insert(cardCompRef.hostView);
          cardCompRef.location.nativeElement.setAttribute(
            'istag',
            'row' + (i == 0 ? '-selected' : '')
          );
          cardCompRef['containerRef'] = bodyCompRef.instance.columnContainer;

          if (i == 0) {
            if(this.userowselect)cardCompRef.location.nativeElement.classList.add('row-selected');
          } else {
            if (columnDef.rowclass) {
              cardCompRef.location.nativeElement.classList.add(
                columnDef.rowclass
              );
            }
          }

          if (!(this.columnKeys[0] == key && this.selectCheckboxColumn)) {
            this.focusAble(cardCompRef, this.headerCompRef[key]);
          } else {
            this.selectAble(cardCompRef, this.headerCompRef[key]);
          }

          this.setStylefunctions(
            cardCompRef,
            this.rowfunctions.functions,
            data[i]
          );
          this.setStylefunctions(cardCompRef, columnDef.functions, data[i]);
          }
        //}})
        }
      } else {
        if (bodyCompRef) {
          if (bodyCompRef.instance) {
            if (bodyCompRef.totallabel) {
              bodyCompRef.instance.label = bodyCompRef.totallabel + data;
            } else {
              bodyCompRef.instance.label = data;
            }
          }
        }
      }
    } else {
      if (bodyCompRef) {
        if (bodyCompRef.instance) {
          if (bodyCompRef.instance.columnContainer) {
            if (data.length > 0) {
              bodyCompRef.instance.label =
                data[0][bodyCompRef.instance.datapath];
            }
          } else {
            if (bodyCompRef.instance.label) {
              bodyCompRef.instance.label = data ? data : null;
            }
          }
        }
      }
    }
  }

  async fillColumn(colname, data, i, value, row, index?) {
    //if (!this.celCompRef[key][j]) {
    let key;
    if (this.columnkeyfunction) {
      key = this.columnkeyfunction(data[i], colname, value);
    } else {
      key = colname;
    }
    if (this.columns[key] /*&& (!data[i].rendered)*/) {
      if (!this.columns[key].hidden) {
        let celRef;
        if (this.columns[key].cellform) {
          celRef = await this.renderCard(
            data[i],
            this.columns[key].cellform,
            this,
            this.columns[key].trackby
          );

          celRef.instance.isparent = this.gridref;

          this.bodyCompRef[key].instance.columnContainer.insert(
            celRef.hostView
          );
          this.celCompRef[i][key] = celRef;
          data[i]['rendered'] = true;
        } else {
          const celcomp = this.compFactory.resolveComponentFactory(
            this.registry.getWidgetType(this.columns[key].bodycomp)
          );
          if (index === null) {
            celRef =
              this.bodyCompRef[key].instance.columnContainer.createComponent(
                celcomp
              );
          } else {
            celRef = this.bodyCompRef[
              key
            ].instance.columnContainer.createComponent(celcomp, index);
          }
          celRef.disablerowselected = this.columns[key].disablerowselected;
          this.celCompRef[i][key] = celRef;

          celRef['column'] = this.columns[key];
          (<any>celRef.instance)['name'] = this.columns[key].name;
          (<any>celRef.instance)['type'] =
            this.columns[key].datatype != null
              ? this.columns[key].datatype
              : null;
          (<any>celRef.instance)['format'] =
            this.columns[key].format != null ? this.columns[key].format : null;
          (<any>celRef.instance)['self'] = celRef.instance;
          (<any>celRef.instance)['data'] = data[i];
          if (this.rowclass) {
            <any>(
              celRef.location.nativeElement.classList.add(
                'grid-' + this.gridref.id + '-' + this.rowclass
              )
            );
          }
          if (this.columns[key].rowclass) {
            <any>(
              celRef.location.nativeElement.classList.add(
                'grid-' + this.gridref.id + '-' + this.columns[key].rowclass
              )
            );
          }
          if (celRef.ngAfterViewInit) {
            celRef.ngAfterViewInit();
          }
          if (this.columns[key].dragstart) {
            celRef.location.nativeElement.setAttribute('draggable', true);
          }
        }

        if (celRef) {
          celRef.location.nativeElement.setAttribute('rowNumber', i);
          celRef.instance['rowId'] = data[i];
          let val;

          if (typeof data[i][this.columns[key].name] !== 'object') {
            // val = data[this.scrolledItems + i][(this.columns[key] as IsColumnDef).name]; //.toString()
            val = data[i][(this.columns[key] as IsColumnDef).name];
            for (const s of this.columns[key].bodycompconfig.static) {
              celRef.instance[s.key] = s.value;
            }

            if (key == 'select') {
              (<any>celRef.instance).rowId = data[i];
              (<any>celRef.instance).gridid = this.gridid;
              (<any>celRef.instance)['name'] = this.columns[key].name;
              (<any>celRef.instance)['selection'] = this.columns[key].selection;
            }

            celRef.instance[this.columns[key].bodycompconfig.data] =
              val as string;
            celRef.changeDetectorRef.detectChanges();
          } else {
            if (this.columns[key].path) {
              const keys = (this.columns[key] as IsColumnDef).path.split('.');
              let val = data[this.scrolledItems + i];
              for (const key of keys) {
                if (key && val) {
                  val = val[key];
                }
              }
              celRef.instance[this.columns[key].bodycompconfig.data] =
                val as string;
              celRef.changeDetectorRef.detectChanges();
            } else {
              val =
                data[this.scrolledItems + i][
                  (this.columns[key] as IsColumnDef).name
                ]; //.toString()

              for (const s of this.columns[key].bodycompconfig.static) {
                celRef.instance[s.key] = s.value;
                celRef.instance[s.key]['rowId'] = data[i];
                celRef.instance[s.key]['gridid'] = this.gridid;
                celRef.instance[s.key]['name'] = (
                  this.columns[key] as IsColumnDef
                ).name;
                celRef.instance[s.key]['selection'] =
                  this.columns[key].selection;
              }
            }
          }

          celRef.location.nativeElement.setAttribute('tabindex', -1);
          if (row) {
            if (row.class) {
              celRef.location.nativeElement.classlist.add(row.class);
            }
            if (row.style) {
              celRef.location.nativeElement.setAttribute('style', row.style);
            }
          }

          if(this.userowselect)celRef.location.nativeElement.setAttribute('istag', 'row' + (i == 0 ? '-selected' : ''));

          if (i == this.selectedRowId) {
            if (!celRef.disablerowselected) {
              const selrowclass = this.selectedrowclass?'grid-' + this.gridref.id + '-' + this.selectedrowclass:'row-selected'; 
              if(this.userowselect){
                celRef.location.nativeElement.classList.add(selrowclass);              
              }  
            }
          } else {
            //celRef.location.nativeElement.classList.add('row')
            if (this.columns[key].rowclass) {
              celRef.location.nativeElement.classList.add(this.columns[key].rowclass);
            }
          }
          if (!(this.columnKeys[0] == key && this.selectCheckboxColumn)) {
            this.focusAble(celRef, this.headerCompRef[key]);
          } else {
            // if(!this._editmode){
            this.selectAble(celRef, this.headerCompRef[key]);
            // } else
            // {
            //  this.focusAble(celRef,this.headerCompRef[j])
            // }
          }
          if (this.columns[key].cellform) {
            // this.updateformData(celRef, this.data[i]);
            this.setStylefunctions(
              celRef,
              this.rowfunctions.functions,
              data[i]
            );
            this.setStylefunctions(
              celRef,
              this.columns[key].functions,
              data[i]
            );
          } else {
            this.setStylefunctions(
              celRef,
              this.rowfunctions.functions,
              data[i]
            );
            this.setStylefunctions(
              celRef,
              this.columns[key].functions,
              data[i]
            );
          }
          (<any>celRef.instance).rownumber = this.scrolledItems + i;
        }
      }
    }
  }

  async fillData(d?) {
    let data = d ? d : this.data;
    if (!data) {
      return;
    }

    if (data.length === 0) {
      this.gridFormGroup.reset();
    }

    if (Object.keys(this.bodyCompRef).length != 0) {
      const col0: any = Object.values(this.bodyCompRef)[0];
      if (col0) {
        this.gridHeight =
          col0.location.nativeElement.parentElement?.parentElement?.getBoundingClientRect().height;

        if (!this.gridHeight) {
          this.gridHeight = 0;
        }

        if (this.gridHeight < this.minheight) {
          this.gridHeight = this.minheight;
        }
      }
      if (this.gridHeight == 0 && this.datamode == 3) {
        this.gridHeight = d.length * this._rowHeight;
      }

      //   if (this.selectCheckboxColumn) {
      //       const set = this.datasets.get('selected');
      //       if (set) {
      //         for (let d of data) {
      //             d['select'] = set.includes(d);
      //         }
      //       }
      //   }

      this.celCompRef = [];
      let numberofrows = this.numberOfRows;
      numberofrows = !this.virtualscroll
        ? data.length
        : numberofrows > data.length
        ? data.length
        : numberofrows;

      this.translatey =
        //0.0 - ( ); //+ 2 * 1.111;

        //-(this._rowHeight - ((this.gridHeight - this.headerHeight ?? 0) - (numberofrows - 1) * this._rowHeight));
        (this.gridHeight - this.headerHeight ?? 0) -
        numberofrows * this._rowHeight;

      if (Math.abs(this.translatey) > this._rowHeight) {
        this.translatey = 0;
      }

      for (let i = 0; i < numberofrows; i++) {
        let row;

        if (data[i]) {
          this.celCompRef.push({});
          //                    for (let [key, value] of Object.entries(this.data[i])) {
          for (const [colname, value] of Object.entries(this.bodyCompRef)) {
            let celRef;

            if (this.columnsInView.includes(colname)) {
              this.fillColumn(colname, data, i, value, row);

              if (data[i].rendered) {
                break;
              }
            }
          }
        }
      }

      if (this.comp.events.get(this.gridid)) {
        const events = this.comp.events.get(this.gridid);

        events.forEach((e) => {
          this.comp.setEvent(this.gridid, e);
        });

        if (this.celCompRef.length > 0) {
          const event = new Event('afterdatarendered');
          this.celCompRef.forEach((comp) => {
            Object.values(comp).forEach((c: ComponentRef<any>) => {
              c.location.nativeElement.dispatchEvent(event);
            });
          });
        }
      }

      // let maxHeight = this.gridHeight;
      // const celHeight = 190;
      // for (const [key, bodycomp] of Object.entries(this.bodyCompRef)) {
      //   const bodyHeight = (bodycomp as any).instance.columnContainer.length * celHeight;
      //   maxHeight = bodyHeight > maxHeight ? bodyHeight : maxHeight;
      // }
      // for (const bodycomp of Object.values(this.bodyCompRef)) {
      //    if (this.virtualscroll) (bodycomp as any).location.nativeElement.style.height = maxHeight?.toString() + 'px';
      // }
      if (this.fakeScroll) {
        this.fakeScroll.nativeElement.style.height = `${this.scrollbarHeight}%`;
        this.fakeScroll.nativeElement.style['min-height'] = this.scrollbarHeight
          ? '32px'
          : '0px';
        //.changeDetectorRef.detectChanges()
      }
    }
    if (this.returnToRecord) {
      this.selectRow(0);
      this.returnToRecord = null;
    } else {
      this.selectRow(0);
    }
    if (this.datasets.get('selected')?.length) {
      //this.transferSelected();
      this.setSelectedData(this.datasets.get('selected'));
      this.updateCheckboxes();
    }
  }

  getdataSelectedRecords(definition) {
    const data = [];
    this.datasets.get('selected')?.forEach((item) => {
      const newdata = {};
      definition.forEach((fc) => {
        const field = fc.controlname.split('.');
        if (parseInt(field[0]) == this.gridref.id) {
          newdata[fc.key] = item[field[1]];
        }
      });
      data.push(newdata);
    });
    return data;
  }

  setColumnWidth() {
    if (this.headerCompRef.length > 0) {
      const col0: any = Object.values(this.headerCompRef)[0];
      let info;
      if (col0) {
        info =
          col0.location.nativeElement.parentElement.getBoundingClientRect();
      }
      if (info.width !== 0) {
        const definedwidth = (Object.values(this.columns) as any).map((col) => {
          if (col.width) {
            if (col.width.includes('px')) {
              return Number(col.width.substring(0, col.width.length - 2));
            }
          } else {
            return 0;
          }
        });

        let presetwidth = 0;
        let amountofpresets = 0;
        for (const d of definedwidth) {
          presetwidth += d;

          if (d > 0) {
            amountofpresets++;
          }
        }

        const width = 100 * ((info.width - presetwidth) / info.width);

        for (const key of this.columnKeys) {
          if (this.columns[key].width || this.columns[key].hidden) {
            /*  this.headerCompRef[key].location.nativeElement.style.width = this.columns[key].hidden? '0%':this.columns[key].width;
                          this.headerCompRef[key].location.nativeElement.style['flex-grow'] = this.columns[key].hidden? '0': '1';
                          this.bodyCompRef[key].location.nativeElement.style.width = this.columns[key].hidden? '0%':this.columns[key].width;
                          this.bodyCompRef[key].location.nativeElement.style['flex-grow'] = this.columns[key].hidden? '0': '1';*/
          } else {
            this.headerCompRef[key].location.nativeElement.style.width =
              (
                width /
                (this.headerCompRef.length - amountofpresets)
              ).toString() + '%';
            this.bodyCompRef[key].location.nativeElement.style.width =
              (width / (this.bodyCompRef.length - amountofpresets)).toString() +
              '%';
          }
        }
      }
    }
  }

  changeVisibility(visible) {
    /*while(this.bodyContainer.length>0){
            this.bodyContainer.detach(0)
        }

        while(this.headerContainer.length>0){
            this.headerContainer.detach(0)
        }*/

    for (let i = 0; i < visible.length; i++) {
      if (visible[i]) {
        this.columns[i].hidden = false;
      } else {
        this.columns[i].hidden = true;
      }
    }

    this.setColumnWidth();
  }

  removeDatasetSubscrription() {
    if (this.DataSubscription) {
      this.DataSubscription.unsubscribe();
      this.DataSubscription = null;
    }
  }

  processloadedData(data, info) {
    // leave this here to avoid flickering
    // clearing should be in same changededtector as creating for smooth master detail scrolling
    // to have no empty displaystate
    //  console.log('start processing');
    //console.log('process loadeddata dataset : ', this.datax, ' data ', this.data, ' info : ', info);
    let keep = {currentrecord:null,sortorder:null}
    if (this.datax && this.keepCurrentrecord){
      keep.currentrecord = this.datax[this.gridref.keyfield]
    }

    this.clearGrid();

    const datadef = this.store.selectSnapshot(
      IsApplicationState.datadef(info.dsname)
    );

    if (this.fielddefs) {
      if (this.isInDesigner) {
        if (datadef) this.fielddefs = datadef;
        this.removeformfields(null, true);
        this.createformfields(this.fielddefs);
      }
    }

    //this.datasets.set('set1', data);
    //this.data = data;
    if (info.sortorder!=null){
      this.sortedcolumns = info.sortorder.split(',');
      data = this.sortData(data);
    }
    if (this.keepSortingstate){
      data = this.sortData(data);
    }
    if (this.keepFilterstate){
      data = this.sortData(data);
    }
    this.setData(data);
    if (this.fielddefs == null && datadef != null) {
      this.createformfields(datadef);
    }
    if (this.pivotdynamicrows) {
      this.setColumnKeys({ columns: info.columns ? info.columns : datadef });
      this.columnKeys.map((key) => {
        if (this.columns[key].pivots) {
          this.columns[key].pivots.map(
            (pivot) => (pivot['calculated'] = false)
          );
          this.createPivotCols(
            this.columns[key].pivots,
            this.bodyCompRef[key],
            0,
            this.headerCompRef[key],
            this.columns[key]
          );
        }
      });
    } else if (info.columns == null && datadef != null) {
      this.createColumns({ columns: datadef, rowfunctions: info.rowfunctions });
    } else if (
      info.columns == null &&
      this.columns == null &&
      this.data.length > 0
    ) {
      this.createColumns({
        columns: Object.keys(data[0]),
        rowfunctions: info.rowfunctions,
      });
    } else if (
      info.columns != null &&
      this.columns == null 
    ) {
      this.createColumns({
        columns: info.columns,
        rowfunctions: info.rowfunctions,
      });
    }


    this.setColumnWidth();

    const toLoad = [];
    let pivots = false;

    for (const col of Object.values(this.columns) as any) {
      if (col.cellform && col.trackby) {
        if (!toLoad.includes(Number(col.trackby))) {
          const widget = this.comp.widgets.get(Number(col.trackby));
          if (!widget) {
            toLoad.push({ formparentid: col.cellform, formid: col.trackby });
          }
        }
      }
      if (col.pivots) {
        if (col.pivots.length > 0) {
          pivots = true;
        }
      }
    }

    if (toLoad.length == 0 && !pivots) {
      this.fillData(data);
    } else {
      if (toLoad.length == 0) {
        if (!pivots) {
          this.fillData(this.data);
        } else {
          for (const c of Object.values(this.columns)) {
            const col = c as any;
            if (c) {
              this.fillData1([col.name], col, col.data ? col.data : this.data);
            }
          }
          // setup events
          if (this.comp.events.get(this.gridid)) {
            const events = this.comp.events.get(this.gridid);

            events.forEach((e) => {
              this.comp.setEvent(this.gridid, e);
            });
          }
        }
        //        console.log('end processing');
      } else {
        for (const load of toLoad) {
          this.store.dispatch(new TrackLoading(load.formid));
          this.store.dispatch(
            new LoadComponent({ id: Number(load.formparentid), name: '' })
          );

          //console.log(`%cClass: IsGridService, Function: processloadedData(load): `, 'color: black;', load);
          const sub = this.outlet.getComponentById$(load.formparentid).pipe(
            // tap((ll) => {
            //   console.log(`%cClass: IsGridService, Function: componentTree(ll): `, 'color: black;', ll);
            // }),
            filter((component) => Boolean(component)),
            take(1)
          );
          sub.subscribe((l) => {
            //console.log(`%cClass: IsGridService, Function: processloadedData.componentLoaded(l): `, 'color: black;', l);
            if (!pivots) {
              this.fillData(this.data);
            } else {
              for (const c of Object.values(this.columns)) {
                const col = c as any;
                if (c) {
                  this.fillData1(
                    [col.name],
                    col,
                    col.data ? col.data : this.data
                  );
                }
              }
            }
          });
        }
      }
    }
    this.dataLoaded.next(true);
    if (this.gridref.ondataLoaded) {
      this.gridref.ondataLoaded(this.data);
    }
  }

  loadCachedData(data, info) {
    this.processloadedData(data, info);
  }

  loadData(info) {
     if (![3,4,5].includes(this.datamode)) this.gridref.loading = true;
     console.log('datamode ',this.gridref.name,' : ',this.datamode)
    this.gridref.cd.detectChanges();

    this.#info = info;
    if (this.removedataset) {
      //console.log('remove dataset : ', info.dsname);
      //this.store.dispatch(new RemoveDataset({ dsname: info.dsname }));
    }

    if ([2, 6].includes(this.datamode)) {
      //console.log(`%cClass: IsGridService, Function:loadData(l): Dispatch LoadDataset`, 'color: green;', info);
      this.store.dispatch(new LoadDataset(info));
      //subloaddataset.subscribe((data) => {
      //  console.log(`%cClass: IsGridService, Function:loadData(l): LoadDataset loaded`, 'color: red;', data, info);
      //});
    }

    if (Number(this.datamode) !== 4 && Number(this.datamode) !== 5) {
      this.store.dispatch(new ClearLoadedSet(info.dsname));
      const data = this.store.select(IsApplicationState.dsset(info.dsname));

      //let first = true;
      //console.log('Subscribe on ', info.dsname);
      this.datasetsubs.forEach((item) => {
        item.unsubscribe();
      });
      this.datasetsubs = [];

      let sub: Subscription | undefined;
      sub = data.subscribe((data) => {
        if (data) {
          this.gridref.loading = false;
          this.gridref.cd.detectChanges();
          //this.removeDatasetSubscrription();
          //console.log('gridservice loaddata dataset : ', info.dsname, 'data ; ', data);
          if (info.master) {
            if (info.master?.master) {
              if (data.length > 0) {
                if (this.detailkey) {
                  const masterRecord = this.gridregistry
                    .get(info.master?.master[0])
                    .data.find(
                      (r) =>
                        r[info.master.iscontrol.name] ===
                        data[0][this.detailkey]
                    );
                  //       .data.find((r) => r[info.master.iscontrol.name] === info.master.iscontrol.formcontrol.value);
                  masterRecord['detail_' + this.gridid] = data;
                }
              }
            }
          }
          if (!Array.isArray(data)) {
            /* problem araise when loading data with definition, most likely missing parameters */
            if (data.missingParams) {
              const newParams = {};
              data.missingParams.map((item) => (newParams[item] = null));
              this.comp.widgets.get(this.gridid)['params'] = [
                ...this.comp.widgets.get(this.gridid)['params'],
                newParams,
              ];
            }
          } else {
            this.processloadedData(data, info);
          }

          if (this.gridref.removeSubscriptions) {
            this.datasetsubs.forEach((item) => {
              item.unsubscribe();
            });
            this.datasetsubs = [];

            sub = null;
          }
        }
      });
      sub ? this.datasetsubs.push(sub) : null;
    } else {
      //const data = this.store.selectSnapshot(IsApplicationState.dataset(info.dsname));
      //      this.data = info.data;
      console.log(
        'process loadeddata dataset : ',
        this.datax,
        ' data ',
        this.data,
        ' info : ',
        info
      );
      this.setData(info.data);
      //this.data = info.data;
      this.processloadedData(this.datax, info);
    }
  }

  sortColumn(colidx) {
    console.log('sort', this.columns[colidx]);
  }

  moveColumn(from, to) {
    this.headerContainer.detach(from);
    this.bodyContainer.detach(from);
    const fromid = this.columnKeys[from];

    this.headerContainer.insert(this.headerCompRef[fromid].hostView, to);
    this.bodyContainer.insert(this.bodyCompRef[fromid].hostView, to);

    this.columnKeys.splice(from, 1);
    this.columnKeys.splice(to, 0, fromid);

    let s = this.getColumnTemplate();
    this.gridref.el.nativeElement.style.setProperty('--columnwidth', s);
    /*
           let toMoveHeader = this.headerCompRef[fromid]
           let toMoveBody = this.bodyCompRef[fromid]
           let toMoveCol = this.columns[fromid]

           this.headerCompRef.splice(from, 1)
           this.bodyCompRef.splice(from, 1)
           this.columns.splice(from, 1)

           this.headerCompRef.splice(to, 0, toMoveHeader)
           this.bodyCompRef.splice(to, 0, toMoveBody)
           this.columns.splice(to, 0, toMoveCol)

           for (let row of this.celCompRef) {
               let toMove = row[from];
               row.splice(from, 1)
               row.splice(to, 0, toMove)
           } */

    for (const [key, value] of Object.entries(this.headerCompRef)) {
      (value as any).instance.colindex = this.columnKeys.indexOf(key);
    }
  }

  currentlyDraggedKey: string;

  headerResize(compRef) {
    let headerDragger;
    let parent;

    let resizeAbleRight;
    let resizeAbleLeft;

    let currentLeft;
    let totalWidth;

    let toDivide;
    let findleft;

    this.activeEvents.push('mousedown');

    compRef.location.nativeElement.addEventListener('mousedown', (ev) => {
      const index = this.columnKeys.indexOf(compRef.instance.colid);
      if (ev.currentTarget.style.cursor == 'col-resize') {
        const info = compRef.location.nativeElement.getBoundingClientRect();

        /* investigate all items right of mousecursor */
        const parentinfo =
          compRef.location.nativeElement.parentElement.getBoundingClientRect();
        totalWidth = parentinfo.width;

        if (ev.clientX > info.left + info.width / 2) {
          // Je zit rechts
          this.currentlyDraggedKey = compRef.instance.colid;
          resizeAbleRight = true;
        } else {
          // Je zit links
          let index = this.columnKeys.findIndex(
            (c: string) => c == compRef.instance.colid
          );
          this.currentlyDraggedKey = this.columnKeys[index - 1];
          resizeAbleLeft = true;
        }

        findleft = index - 1;
        /*
                    if(resizeAbleLeft){
                        while(findleft>0|| this.columns[this.columnKeys[findleft]].hidden){
                            findleft--
                        }
                    }*/

        currentLeft = resizeAbleRight
          ? info.left
          : this.headerCompRef[
              this.columnKeys[findleft]
            ]?.location.nativeElement.getBoundingClientRect().left;

        const sizeAvailable =
          this.headerCompRef[
            this.columnKeys[index - (resizeAbleLeft ? 1 : 0)]
          ]?.location.nativeElement.getBoundingClientRect();

        toDivide = sizeAvailable?((parentinfo.right - sizeAvailable.left) / parentinfo.width):0;

        headerDragger =
          compRef.location.nativeElement.parentElement.parentElement.parentElement.querySelector(
            '.headerDragger'
          );
        parent = headerDragger.parentElement.getBoundingClientRect();
        headerDragger.style.display = 'flex';
        headerDragger.style.left = (ev.clientX - parent.left).toString() + 'px';

        const height =
          info.height +
          this.bodyCompRef[
            this.columnKeys[0]
          ]?.location.nativeElement.getBoundingClientRect().height;

        headerDragger.style.height = height.toString() + 'px';
      }
    });

    this.activeEvents.push('mousemove');

    compRef.location.nativeElement.addEventListener('mousemove', (ev) => {
      const info = ev.currentTarget.getBoundingClientRect();
      const index = this.columnKeys.indexOf(compRef.instance.colid);

      if (
        (!this.columns[compRef.instance.colid].hidden &&
          ev.clientX - info.left < 5 &&
          index > 0) ||
        (info.right - ev.clientX < 5 &&
          index < Object.keys(this.columns).length - 1)
      ) {
        ev.currentTarget.style.cursor = 'col-resize';
      } else {
        ev.currentTarget.style.cursor = 'default';
      }
    });

    this.activeEvents.push('mouseup');

    window.addEventListener('mouseup', () => {
      //const index = this.columnKeys.indexOf();

      if (headerDragger) {
        const newSize =
          headerDragger.getBoundingClientRect().left - currentLeft;

        this.columns[this.currentlyDraggedKey].width =
          newSize.toString() + 'px';

        this.gridref.el.nativeElement.style.setProperty(
          '--columnwidth',
          this.getColumnTemplate()
        );

        // const sizeLeft = toDivide - newSize;

        // const right =
        //   this.headerCompRef[this.columnKeys[this.columnKeys.length - 1]].location.nativeElement.getBoundingClientRect()
        //     .right;
        // const left =
        //   this.headerCompRef[
        //     this.columnKeys[resizeAbleRight ? index + 1 : findleft]
        //   ].location.nativeElement.getBoundingClientRect().left;

        // const a =
        //   this.headerCompRef[this.columnKeys[resizeAbleRight ? index + 1 : findleft + 1]].location.nativeElement;

        // const divideableWidth = right - left;

        // const fraction = [];

        // for (let i = resizeAbleRight ? index + 1 : findleft + 1; i < this.columnKeys.length; i++) {
        //   if (!this.headerCompRef[this.columnKeys[i]].hidden) {
        //     const info = this.headerCompRef[this.columnKeys[i]].location.nativeElement.getBoundingClientRect();
        //     fraction.push(info.width / divideableWidth);
        //   } else {
        //     fraction.push(0);
        //   }
        // }

        // this.headerCompRef[this.columnKeys[resizeAbleRight ? index : findleft]].location.nativeElement.style.width =
        //   (100 * newSize).toString() + '%';
        // this.bodyCompRef[this.columnKeys[resizeAbleRight ? index : findleft]].location.nativeElement.style.width =
        //   (100 * newSize).toString() + '%';

        // for (let i = resizeAbleRight ? index + 1 : findleft + 1; i < this.columnKeys.length; i++) {
        //   const otherNew = 100 * sizeLeft * fraction[i - (resizeAbleRight ? index + 1 : findleft + 1)];

        //   //if(!this.columns[i].hidden){

        //   this.headerCompRef[this.columnKeys[i]].location.nativeElement.style.width = otherNew.toString() + '%';
        //   this.bodyCompRef[this.columnKeys[i]].location.nativeElement.style.width = otherNew.toString() + '%';

        //   //}
        // }
        headerDragger.style.display = 'none';
        resizeAbleLeft = false;
        resizeAbleRight = false;
        headerDragger = undefined;
      }
    });

    this.activeEvents.push('mousemove');

    window.addEventListener('mousemove', (ev) => {
      if (headerDragger && ev.clientX - currentLeft > 20) {
        headerDragger.style.left = (ev.clientX - parent.left).toString() + 'px';
      }
    });
  }

  resetNumberinput() {
    this.newNumber = '';
    if (this['afterresetnumberInput']) {
      this['afterresetnumberInput']();
    }
  }

  storeSelectedCards() {
    if (this.updatecommand) {
      const updatedata = Array.from(this.selectedcards, ([key, value]) => {
        return this.gridref.removenonupdatefields(value['data']);
      });

      const sub = this.store.dispatch({
        type: '[Execute]',
        payload: { command: this.updatecommand, data: updatedata },
      });
      const subscription = sub.subscribe((item) => {
        subscription.unsubscribe();
        if (this.gridref.onUpdate) {
          this.gridref.onUpdate(item, null);
        }
      });
    }
  }

  deleteSelectedCard() {
    if (this.deletecommand) {
      const deletedata = Array.from(this.selectedcards, ([key, value]) => {
        const deldata = {};
        deldata[this.gridref.keyfield] = value['data'][this.gridref.keyfield];

        const cardRef = this.findCard(value);
        const items = cardRef['bodyCompRef'].instance['items'];
        const idx = items.findIndex((d) => {
          return (
            d[this.gridref.keyfield] == value['data'][this.gridref.keyfield]
          );
        });
        cardRef['containerRef'].detach(idx);

        return deldata;
      });
      const sub = this.store.dispatch({
        type: '[Execute]',
        payload: { command: this.deletecommand, data: deletedata },
      });
    }
  }

  setNumberSelectedCard(number, grid) {
    Array.from(this.selectedcards, ([key, value]) => {
      console.log(value);
      if (value['updateNumber']) {
        value['updateNumber'](number, value, grid);
        this.refreshCard(this.findCard(value));
      }
    });
    this.storeSelectedCards();
  }

  gridEvents(bodyRef, grid?: IsGridService) {
    this.activeEvents.push('keydown');
    bodyRef.setAttribute('tabindex', '-1');
    bodyRef.addEventListener('keydown', (ev) => {
      if (ev.ctrlKey) {
        switch (ev.key) {
          case 'a': {
            ev.preventDefault();
            ev.stopPropagation();
            grid.selectAllCards();

            break;
          }
        }
        return;
      }
      if (ev.key == 'Escape') {
        this.resetNumberinput();
        ev.preventDefault();
        ev.stopPropagation();
        grid.clearSelectedCards();
        return;
      }
      if (ev.key == 'Enter') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.newNumber != '') {
          grid.setNumberSelectedCard(this.newNumber, grid);
          grid.resetNumberinput();
        }
        return;
      }
      if (ev.key == 'Delete') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.newNumber == '') {
          grid.deleteSelectedCard();
        } else {
          grid.newNumber += ev.key;
        }
        return;
      }
      if (ev.key == 'Backspace') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.newNumber != '') {
          grid.newNumber += ev.key;
        }
        return;
      }
      if (isFinite(ev.key) || ev.key == '.') {
        ev.preventDefault();
        ev.stopPropagation();
        grid.newNumber += ev.key;
        console.log(grid.newNumber);
        return;
      }
    });
  }

  focusAble(celRef, column: ComponentRef<any>) {
    if (!column) return;
    this.activeEvents.push('click');

    celRef.location.nativeElement.addEventListener('click', () => {
      const idx = this.celCompRef.findIndex((cel) => {
        return celRef == cel[column.instance.colid];
      });
      if (this.searchstr) {
        //this.resetMarkingSearch();
        if (idx != this.selectedRowId || this.selectedColumnId !== column.instance.colid) {
          this.removemarkingSearch('g');
        }
      }
      if (idx >= 0) {
        this.selectRow(idx, column.instance.colid, null, true);
      }
    });

    celRef.location.nativeElement.addEventListener('blur', () => {
      //      console.log('blur')
    });

    this.activeEvents.push('keydown');

    celRef.location.nativeElement.addEventListener('keydown', (ev) => {
      //console.log(ev.key);
      if (ev.key === ' ' && this.selectCheckboxColumn) {
        this.celCompRef[this.selectedRowId].select.location.nativeElement
          .querySelector('iscustomcheckbox')
          .click();
      }
      if (ev.key == 'F6') {
        this.browsemode =
          this.browsemode == 1 ? this.browsemode + 1 : this.browsemode - 1;
        //if (this.browsemode == 1){
        this.resetFilter();
        //}  
      }
      if (ev.key == 'ArrowDown') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.selectedRowId == this.numberOfRows - 1) {
          if (this.virtualscroll) {
            this.moveViewDown(null, null, column.instance.colid, true);
            this.focusedCel(
              this.focusedCelRef,
              this.selectedRowId,
              column.instance.colid
            );
          }
          return;
        }
        this.searchstr = '';
        //this.updateMarkingSearch(this.selectedColumnId);
        this.removemarkingSearch('h');
        this.selectRow(this.selectedRowId + 1, column.instance.colid);
      } else if (ev.key == 'ArrowUp') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.selectedRowId == 0) {
          if (this.virtualscroll) {
            this.moveViewUp(null, column.instance.colid, true);
            this.focusedCel(
              this.focusedCelRef,
              this.selectedRowId,
              column.instance.colid
            );
          }
          return;
        }
        this.searchstr = '';
        //this.updateMarkingSearch(this.selectedColumnId);
        this.removemarkingSearch('i');
        this.selectRow(this.selectedRowId - 1, column.instance.colid);
      } else if (ev.key == 'PageDown') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.selectedRowId == this.numberOfRows - 1) {
          if (this.virtualscroll) {
            this.moveViewDown(null, null, column.instance.colid, true);
          }
          return;
        }

        this.selectRow(this.selectedRowId + this.numberOfRows);
      } else if (ev.key == 'PageUp') {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.selectedRowId == 0) {
          this.moveViewUp(null, column.instance.colid, true);
          return;
        }
        this.removemarkingSearch('j');
        this.selectRow(this.selectedRowId - 1);
      } else {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.browsemode == 1) {
          //const col = ev.path.find((x) => x.nodeName == 'ISCOLUMN');
          if (column) {
            this.filterData(column.instance.colid, ev.key);
          }
        //} //else if (this.browsemode == 2) {
          // editmode
        } else if (this.browsemode == 2) {
          //const col = ev.path.find((x) => x.nodeName == 'ISCOLUMN');
          if (column) {
            console.log('search ', ev.key);
            this.searchData(column.instance.colid, ev.key);
          }
        }
        // let col = ev.path.find(x => x.nodeName == 'ISCOLUMN');
        // if (col) {
        //     this.searchData(col.attributes['columnkey'].value, ev.key);
        // }
      }
    });

    this.activeEvents.push('wheel');

    celRef.location.nativeElement.addEventListener(
      'wheel',
      (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.deltaY < 0) {
          if (this.selectedRowId == 0) {
            if (this.virtualscroll) {
              this.moveViewUp(null, column.instance.colid, true);
            }
            return;
          }
          this.removemarkingSearch('k');
          this.selectRow(this.selectedRowId - 1, column.instance.colid);
        }
        if (ev.deltaY > 0) {
          if (this.selectedRowId == this.numberOfRows - 1) {
            //if (this.selectedRowId+1 == this.numberOfRows-1) {  RPR
            if (this.virtualscroll) {
              this.moveViewDown(null, null, column.instance.colid, true);
            }
            return;
          }
          this.removemarkingSearch('l');
          this.selectRow(this.selectedRowId + 1, column.instance.colid);
        }
      },
      { passive: false }
    );

    let touchY = null;

    if (!this.activeEvents.includes('touchstart')) {
      this.activeEvents.push('touchstart');

      celRef.location.nativeElement.parentElement?.parentElement?.parentElement?.addEventListener(
        'touchstart',
        (ev) => {
          // ev.preventDefault();
          ev.stopPropagation();
          if (!touchY) {
            touchY = ev.touches[0].screenY;
          }
        },
        { passive: false }
      );
    }

    if (!this.activeEvents.includes('touchmove')) {
      this.activeEvents.push('touchmove');

      celRef.location.nativeElement.parentElement?.parentElement?.parentElement?.addEventListener(
        'touchmove',
        (ev) => {
          ev.preventDefault();

          if (touchY) {
            if (ev.changedTouches[0].screenY - touchY > 30) {
              this.moveViewUp(true, column.instance.colid, true);
              touchY = ev.changedTouches[0].screenY;
            } else if (ev.changedTouches[0].screenY - touchY < -30) {
              this.moveViewDown(true, null, column.instance.colid, true);
              touchY = ev.changedTouches[0].screenY;
            }
          }
        },
        { passive: false }
      );
    }

    if (!this.activeEvents.includes('touchend')) {
      this.activeEvents.push('touchend');

      celRef.location.nativeElement.parentElement?.parentElement?.parentElement?.addEventListener(
        'touchend',
        (ev) => {
          // ev.preventDefault();
          ev.stopPropagation();
          touchY = null;

          window.removeEventListener('mousemove', mouseMoveScrollHandler);
          window.removeEventListener('touchmove', touchMoveScrollHandler);
        },
        { passive: false }
      );
    }

    const mouseMoveScrollHandler = {
      handleEvent: (ev) => {
        if (this.fakeScroll) {
          const box =
            this.fakeScroll.nativeElement.parentElement.getBoundingClientRect();
          const localOffset = Math.max(
            Math.min(ev.clientY, box.bottom),
            box.top
          );
          const height = box.bottom - box.top;
          const offset = localOffset - box.top;
          const ratio = (100 * offset) / height;
          this.scrolledItems = Math.floor(
            Math.min(
              (ratio * this.datax.length) / 100,
              this.datax.length - this.numberOfRows
            )
          );
          this.updateScroll(this.celCompRef, this.scrolledItems);
          this.updateScrollbarPosition();
          this.selectRow(this.selectedRowId);
        }
      },
    };

    const touchMoveScrollHandler = {
      handleEvent: (ev) => {
        if (this.fakeScroll) {
          const box =
            this.fakeScroll.nativeElement.parentElement.getBoundingClientRect();
          const localOffset = Math.max(
            Math.min(ev.touches[0].clientY, box.bottom),
            box.top
          );
          const height = box.bottom - box.top;
          const offset = localOffset - box.top;
          const ratio = (100 * offset) / height;
          this.scrolledItems = Math.floor(
            Math.min(
              (ratio * this.datax.length) / 100,
              this.datax.length - this.numberOfRows
            )
          );
          this.updateScroll(this.celCompRef, this.scrolledItems);
          this.updateScrollbarPosition();
          this.selectRow(this.selectedRowId);
        }
      },
    };

    const mouseUpScrollHandler = {
      handleEvent: (ev) => {
        window.removeEventListener('mousemove', mouseMoveScrollHandler);
        window.removeEventListener('touchmove', touchMoveScrollHandler);
      },
    };

    if (!this.activeEvents.includes('mousedown-scroll')) {
      this.activeEvents.push('mousedown-scroll');
      if (this.fakeScroll) {
        this.fakeScroll.nativeElement.addEventListener('mousedown', (ev) => {
          if (this.gridFormGroup.valid) {
            window.addEventListener('mousemove', mouseMoveScrollHandler);
          }
        });
      }
    }

    if (!this.activeEvents.includes('touchstart-scroll')) {
      this.activeEvents.push('touchstart-scroll');
      if (this.fakeScroll) {
        this.fakeScroll.nativeElement.addEventListener('touchstart', (ev) => {
          window.addEventListener('touchmove', touchMoveScrollHandler);
        });
      }
    }

    if (!this.activeEvents.includes('mouseup-scroll')) {
      this.activeEvents.push('mouseup-scroll');

      window.addEventListener('mouseup', mouseUpScrollHandler);
    }

    this.activeEvents.push('scroll');

    celRef.location.nativeElement.parentElement?.parentElement?.parentElement?.addEventListener(
      'scroll',
      (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        //console.log('isgrid : scroll event', ev);
      },
      { passive: false }
    );
  }

  updateScroll(rowRefs, datapos) {
    this.removemarkingSearch('m',false);

    const pos = datapos;
    rowRefs.forEach((rowRef) => {
      for (const [key, col] of Object.entries(this.bodyCompRef)) {
        if (
          (col as any).instance.columnContainer.length > 0 &&
          datapos < this.datax.length
        ) {
          const coldef: any = this.columns[key];
          this.fillCell(rowRef[key], datapos, coldef);
          //rowRef[key].location.nativeElement.setAttribute('rownumber', datapos);

          if (coldef.name == 'select') {
            this.setRowIdInInstance(rowRef[key], datapos);
          }
        }
        (col as ComponentRef<IsColumnDef>).changeDetectorRef.detectChanges();
      }
      datapos += 1;
    });
  }

  setCurrentRecord(key,record){
    const r = this.data.find(item => item[key] == record[key]);
    if (r){
      Object.assign(r,record);
      this.updateCurrentRecord();
      if (this.gridref.dsname){
         this.store.dispatch({type:'SetDataset',payload:{dsname:this.gridref.dsname,dataset:this.data}});
      }
    }
  }

  updateCurrentRecord(definition?:any) {
    const pos = this.selectedRowId;
    this.selectRow(this.selectedRowId);
    const rowRef = this.celCompRef[this.selectedRowId];
    for (const [key, col] of Object.entries(rowRef)) {
      if ((col as any).instance) {
        const coldef: any = this.columns[key];
        if (this.columns[key].format && definition){
          if (this.columns[key].datatype == 'datetime' && this.datax[pos][key]){
            this.datax[pos][key] = this.comp.setdatetoformat(this.datax[pos][key],definition[key],this.columns[key].format)
          }
        }
        this.fillCell(rowRef[key], pos, coldef);
      }
      (col as ComponentRef<IsColumnDef>).changeDetectorRef.detectChanges();
    }
  }

  selectAble(comp, column) {
    /*comp.changeDetectorRef.detectChanges()

        let checkbox = comp.location.nativeElement//.querySelector('input')

        this.activeEvents.push('click')

        checkbox.addEventListener('click', () => {
            comp.instance.checkedVal = !comp.instance.checkedVal

            let idx = this.celCompRef.findIndex(cel => { return comp == cel[column.instance.colid] })

            this.data[idx + this.scrolledItems].select = comp.instance.checkedVal;

            if (comp.instance.checkedVal) {
                this.celCompRef[idx].map(
                    cel => {
                        cel.location.nativeElement.classList.add('row-selectedtest')
                    }
                )
            } else {
                this.headerCompRef[0].instance.ischecked = false;

                this.celCompRef[idx].map(
                    cel => {
                        cel.location.nativeElement.classList.remove('row-selectedtest')
                    }
                )
            }
        })*/
  }

  storeupdateddata() {
    const sqlstoredata = [];
    for (const item of this.updateddata) {
      let updkey = ' ';
      let andstr = ' ';
      const table = this.tablename ? this.tablename : 'tbl_forms ';
      let sql = 'update ' + table + ' set ';
      const upddata = {};
      for (const [key, value] of Object.entries(item)) {
        if (key != 'key') {
          sql = sql + '\n' + key + '=:' + key;
          upddata[key] = value;
        } else {
          for (const [key, vdata] of Object.entries(value)) {
            updkey = andstr + updkey + ' ' + key + '=' + vdata;
            andstr = ' and ';
          }
        }
      }
      sql = sql + '\nwhere ' + updkey;
      sqlstoredata.push({ command: sql, params: upddata });
    }
    console.log(sqlstoredata);
    this.store.dispatch(new ExecQry(this.account, sqlstoredata));
    this.updateddata = [];
  }

  storedeleteddata() {}

  drawSelectedCard(compRef, remove) {
    if (remove) {
      const cmpel = this.comp.el(this.gridid);
      const oldsel = cmpel.querySelectorAll('isform[selected]');
      if (oldsel) {
        oldsel.forEach((item) => {
          item.removeAttribute('selected');
          item.style['outline'] = 'none';
        });
      }
    }
    if (compRef) {
      const cardel = this.comp.el(compRef.id);
      cardel.setAttribute('selected', true);
      cardel.style['outline'] = 'solid black 2px';
    }
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        console.log('not equal : ', a[i], b[i]);
        return false;
      }
    }
    return true;
  }

  triggerDetail(selected, componentref?, ev?) {
    if (selected != null) {
      const defs = this.fielddefs ? this.fielddefs : this.columns;
      if (defs) {
        for (let i = 0; i < Object.values(defs).length; i++) {
          if (!(i == 0 && this.selectCheckboxColumn)) {
            const column: any = Object.values(defs)[i];
            if (this.datasets.get('selected')?.length) {
              //this is test for array and length>0
              //multiselect master
              if (
                this.prevselected == null ||
                !this.arraysEqual(
                  this.prevselected,
                  this.datasets.get('selected')
                )
              ) {
                const control = this.comp.formcontrols.get(
                  this.gridid.toString() + '.' + column.name
                );
                if (control) {
                  control.formcontrol['master'] = control.master;
                  let str = '';
                  this.datasets.get('selected')?.forEach((option) => {
                    str += `${option[column.name]},`;
                  });
                  str = str.substr(0, str.length - 1);
                  control.formcontrol.setValue(str);
                }
                this.prevselected = [...this.datasets.get('selected')];
              }
            } else {
              const control = this.comp.formcontrols.get(
                this.gridid.toString() + '.' + column.name
              );

              if (control) {
                control.formcontrol['master'] = control.master;
                control.formcontrol.setValue(selected[column.name]);
              }
            }
          }
        }
      }
      if (this.selectedcards != null) {
        if (componentref) {
          if (ev?.ctrlKey == false && !ev?.shiftKey) {
            this.selectedcards.clear();
          }

          if (ev?.shiftKey && this.selectedcards.size === 1) {
            this.selectedcards;
            const [firstcard] = this.selectedcards.values();

            const startIdx = this.cardlist.findIndex(
              (c: ComponentRef<any>) => c.instance === firstcard
            );
            const endIdx = this.cardlist.findIndex(
              (c: ComponentRef<any>) => c.instance === componentref
            );

            for (
              let i = (startIdx < endIdx ? startIdx : endIdx) + 1;
              i < (startIdx < endIdx ? endIdx : startIdx);
              i += 1
            ) {
              const card = this.cardlist[i];

              this.selectedcards.set(
                card.instance.id as any,
                card.instance as any
              );

              if (card.instance['setselected']) {
                card.instance['setselected'](true, card);

                card.changeDetectorRef.detectChanges();
              }
              if (!card['ondrawselected']) {
                this.drawSelectedCard(card.instance, false);
              }

              // if (this.gridcomp.setselectedCards) {
              //   this.gridcomp.setselectedCards();
              // }
            }
          }

          this.selectedcards.set(componentref.id, componentref);
          //this.renderer.addClass(.el.nativeElement;
          if (componentref['setselected']) {
            componentref['setselected'](true, componentref);

            componentref.cd.detectChanges();
          }
          if (!componentref['ondrawselected']) {
            this.drawSelectedCard(
              componentref,
              ev?.ctrlKey == false && ev?.shiftKey == false
            );
          }

          if (this.gridcomp.setselectedCards) {
            this.gridcomp.setselectedCards();
          }
        }
      }
    }
  }


  clearSelectedCards() {
    this.drawSelectedCard(null, true);
    this.selectedcards.clear();
    if (this.gridcomp.afterClearCards) {
      this.gridcomp.afterClearCards();
    }
  }

  selectAllCards() {
    this.cardlist.forEach((item) => {
      //const compRef = this.comp.gc(item.id);
      this.selectedcards.set(item.instance.id.toString(), item);
      this.drawSelectedCard(item.instance, false);
    });
  }

  translateyColumn(translation) {
    for (const value of Object.values(this.bodyCompRef)) {
      if (this.virtualscroll) (value as any).instance.setScroll(translation);
    }
  }

  selectRow(index, colid?, scrolledUp?: boolean, fromclick?: boolean) {
    //if(this.searchCell){
    //    this.searchCell.location.nativeElement.innerHTML = this.searchCell.location.nativeElement.innerText;
    //    this.searchCell.changeDetectorRef.detectChanges();
    //    this.searchCell = null;
    //}
    if (!this.columns) {
      return;
    }
    if (
      (this.selectedRowId !== index || this.selectedColumnId !== colid) &&
      this.focusedCelRef &&
      !scrolledUp
    ) {
      if (this.gridFormGroup.valid || this.selectedRowId == index) {
        const row = this.datax[this.scrolledItems + this.selectedRowId];
        const keys =
          this.columns[this.selectedColumnId].edittodata.data.split('.');

        let val = this.focusedCelRef.instance;

        for (const key of keys) {
          val = val[key];
        }

        if (row[this.columns[this.selectedColumnId].name] !== val) {
          if (this.primarykeys) {
            const item = {};
            item['key'] = {};
            for (const key of this.primarykeys) {
              item['key'][key] = row[key];
            }
            item[this.columns[this.selectedColumnId].name] = val;
            this.updateddata.push(item);
            /* push it to the database */
          }

          row[this.columns[this.selectedColumnId].name] = val;
          const cel =
            this.celCompRef[this.selectedRowId][this.selectedColumnId].instance;
          const key = this.columns[this.selectedColumnId].bodycompconfig.data;
          cel[key] = row[this.columns[this.selectedColumnId].name];
          if (this.columns[this.selectedColumnId]?.afteredit) {
            const edited =
              this.columns[this.selectedColumnId]?.afteredit(row, val) ?? null;
            if (edited) {
              //this.celCompRef[this.selectedRowId][edited[0]].instance?.render ??
              this.celCompRef[this.selectedRowId][edited[0]].instance[
                this.columns[edited[0]].bodycompconfig.data
              ] = row[edited[0]] as string;
            }
          }
        }

        if (!scrolledUp) {
          this.bodyCompRef[
            this.selectedColumnId
          ].instance.columnContainer.remove(this.selectedRowId);
          //this.focusedCelRef.instance.label = this.gridFormGroup.get(this.columns[this.selectedColumnId].name).value;
          this.bodyCompRef[
            this.selectedColumnId
          ].instance.columnContainer.insert(
            this.celCompRef[this.selectedRowId][this.selectedColumnId].hostView,
            this.selectedRowId
          );
        }
      } else {
        Object.values(
          this.gridFormGroup.errors ? this.gridFormGroup.errors : {}
        ).map((e) => {
          alert(e.errormessage);
        });
        return;
      }
    }

    let selectedrow: any = {};
    this.celCompRef;

    selectedrow = this.datax[this.scrolledItems + index];
    this.selectedRecord = selectedrow;

    this.triggerDetail(selectedrow);

    const selectedFieldDefs = {};

    if (this.gridFormGroup.value && selectedrow) {
      const keys = this.gridFormGroup
        ? Object.keys(this.gridFormGroup.value)
        : [];

      for (const k of keys) {
        selectedFieldDefs[k] = selectedrow[k] ? selectedrow[k] : null;
      }

      if (selectedFieldDefs) {
        this.gridFormGroup?.setValue(selectedFieldDefs);
      }
    }

    // for (let i = 0; i < Object.values(this.columns).length; i++) {
    //     if (!(i == 0 && this.selectCheckboxColumn)) {
    //         let column: any = Object.values(this.columns)[i];
    //         if(!column.hidden){
    //           let cellvalue
    //           if(this.celCompRef[index]){
    //             cellvalue = (Object.values(this.celCompRef[index])[column.name] as ComponentRef<any>).instance.label;
    //           }
    //           selectedrow[column.name] = cellvalue;

    //           let control = this.comp.formcontrols.get(this.gridid.toString() + '.' + column.name);
    //           if (control) {
    //               control.formcontrol['master'] = control.master;
    //               control.formcontrol.setValue(cellvalue)
    //           }
    //         }
    //     }
    // }
    //console.log(this.gridFormGroup.value,selectedrow);

    /*if(colid==null || colid==undefined){
            this.celCompRef[index][0].location.nativeElement.focus()
        }*/

    const column = this.columns[colid] as IsColumnDef;
    const edit = column?.editfunction
      ? column?.editfunction(selectedrow, column, this)
      : column?.edit;
    if ((colid || colid == 0 ? edit : false) && this.editmode) {
      this.bodyCompRef[colid].instance.columnContainer.detach(index);

      const editcomp = this.compFactory.resolveComponentFactory(
        this.registry.getWidgetType(column.editcomp)
      ) as any;

      const ref = this.bodyCompRef[
        colid
      ].instance.columnContainer.createComponent(editcomp, index) as any;
      ref.location.nativeElement.classList.add('grid');
      // let ref = null;

      /* set parameters of complex object */

      for (const s of column.datatoedit.static) {
        let val = ref.instance;
        const keys = s.key.split('.');
        const last = keys.pop();
        for (const key of keys) {
          val = val[key];
        }
        val[last] = s.value; //this.gridFormGroup.get(column.name);
      }
      //ref.instance.formControl =
      /* set data of complex object */
      /* make that ngInit() and ngAfter have been run */
      const keys = column.datatoedit.data?.split('.');

      if (column.datatoedit.cd) {
        ref.changeDetectorRef.detectChanges();
      }
      let val = ref.instance;

      if (keys) {
        const last = keys.pop();

        for (const key of keys) {
          val = val[key];
        }
        val[last] = this.gridFormGroup.get(column.name);

        // if (column.datatoedit.setval) {

        //   val.setValue(this.datax[this.scrolledItems + index][column.name]);
        // } else {
        //     val[last] = this.datax[this.scrolledItems + index][column.name];
        // }
      }
      ref.location.nativeElement.style.width = '100%';
      ref.location.nativeElement.style.height = '30px';
      //ref.location.nativeElement.style.outline = 'blue 1px solid'
      //(this.gridFormGroup.get(this.columns[colid].name) as FormControl)
      ref.changeDetectorRef.detectChanges();
      this.focusedCelRef = ref;
      this.focusedCel(ref, index, colid);
    } else {
      this.focusedCelRef = undefined;
    }

    if (this.celCompRef[this.selectedRowId]) {
      for (const ref of Object.values(this.celCompRef[this.selectedRowId])) {
        //(ref as any).location.nativeElement.setAttribute('istag', 'row')
        // (ref as any).location.nativeElement.classList.add('row')
        const selrowclass = this.selectedrowclass?'grid-' + this.gridref.id + '-' + this.selectedrowclass:'row-selected';
        if(this.userowselect)(ref as any).location.nativeElement.classList.remove(selrowclass);

        if (
          (ref as any).location.nativeElement.classList.contains(
            'cell-selected'
          )
        ) {
          (ref as any).location.nativeElement.classList.remove('cell-selected');
        }
        (ref as any).changeDetectorRef.detectChanges();
        //ref.location.nativeElement.classList.add('row')
      }
    }

    this.selectedRowId = index;
    if (colid && this.selectedColumnId) {
      if (this.selectedColumnId !== colid && this.searchstr != '') {
        this.searchstr = '';        
        this.updateMarkingSearch(this.selectedColumnId,this.selectedRowId,'b');
      }
    }

    this.selectedColumnId = colid ? colid : this.selectedColumnId;

    /* change translation of body when move to top or bottom row , leave postion otherwise the same */
    if (this.selectedRowId == this.numberOfRows - 1) {
      this.translateyColumn(this.translatey);
    } else if (this.selectedRowId == 0) {
      this.translateyColumn(0);
    }

    if (this.celCompRef[index]) {
      for (const [key, value] of Object.entries(this.celCompRef[index])) {
        // (ref as any).location.nativeElement.setAttribute('istag', 'row-selected')
        if (!(value as any).disablerowselected) {
          if(this.userowselect){
            const selrowclass = this.selectedrowclass?'grid-' + this.gridref.id + '-' + this.selectedrowclass:'row-selected';
            (value as any).location.nativeElement.classList.add(selrowclass);            
            (value as any).changeDetectorRef.detectChanges();
          }
        }
        //  (ref as any).location.nativeElement.classList.remove('row')
        //ref.location.nativeElement.classList.remove('row')
        //ref.location.nativeElement.focus()

        //  (ref as any).changeDetectorRef.detectChanges()
        if (key == colid) {
          (value as any).location.nativeElement.classList.add('cell-selected');
          if (this.gridref.cellClick) {
            this.gridref.cellClick(
              this.data[index][key],
              this.celCompRef[index][key]
            );
          }

          // const event = new CustomEvent('cellClick', {
          //   bubbles: false,
          //   detail: { cell: this.celCompRef[index][key], data: this.data[index][key] },
          // });
          // (value as any).location.nativeElement.dispatchEvent(event);
        }
      }
    }

    /*if(colid==null || colid==undefined){
            this.celCompRef[index][0].location.nativeElement.focus()
        }*/

    const event = new CustomEvent('afterscroll', {
      bubbles: true,
      detail: { rowid: this.selectedRowId, data: selectedrow },
    });
    this.bodyContainer.element.nativeElement.parentElement?.parentElement?.dispatchEvent(
      event
    );

    if (fromclick) {
      const event = new CustomEvent('rowclick', {
        bubbles: true,
        detail: { rowid: this.selectedRowId, data: selectedrow },
      });
      this.bodyContainer.element.nativeElement.parentElement?.parentElement?.dispatchEvent(
        event
      );
    }

    //this.afterscroll$.next({ rowid: this.selectedRowId, data: selectedrow });

    //let el  = this.bodyContainer.element.nativeElement.parentElement.parentElement;
    //(el as any)['dispatchEvent']( event);
  }

  focusedCel = (ref, index, col) => {
    if (this.celCompRef.length > 0) {
      const cel = this.celCompRef[index][col];
      const e = ref ? ref : cel;
      e.location.nativeElement.setAttribute('tabindex', -1);
      e.location.nativeElement.focus();
    }
    //ref.location.nativeElement.addEventListener('focus',(ev) => {
    //    debugger
    //})

    //ref.location.nativeElement.addEventListener('blur',(ev) => {
    /*if(this.focusedCelRef==ref){
            debugger;
        }*/

    //if(this.gridFormGroup.valid){
    //this.bodyCompRef[col].instance.columnContainer.detach(index)
    //this.data[this.scrolledItems+index][this.columns[col].name] = this.gridFormGroup.get(this.columns[col].name).value;

    //cel.instance.label = this.gridFormGroup.get(this.columns[col].name).value;
    //this.bodyCompRef[col].instance.columnContainer.insert(cel.hostView,index)
    //}

    /*else {
            this.selectRow(index,col)
        }*/

    //})
  };

  updateformData(item, data) {
    let id = (item.instance as any).id;
    id = id?id:(item.instance as any).formid;
    const c = this.comp.widgets.get(id);
    item.instance.data = data;
    if (c['ischildren']) {
      c['ischildren'].forEach((child) => {
        if (child.instance.data) {
          child.instance.data = data;
        }
      });
    }
  }

  transferSelected() {
    if (this.datasets.get('selected')?.length) {
      const oldSel = [...this.datasets.get('selected')];
      const newSel = [];
      if (oldSel) {
        this.gridref['selectionfields'] = this.gridref['selectionfields']
          ? [...this.gridref['selectionfields'], 'select']
          : ['select'];
      }
      oldSel.forEach((oldselitem) => {
        const idx = this.datax.findIndex((item) => {
          let result = true;

          for (const field of this.gridref['selectionfields']) {
            if (item[field] != oldselitem[field]) {
              result = false;
              break;
            }
          }

          return result;
        });
        if (idx > -1) {
          newSel.push(this.datax[idx]);
        }
      });
      this.datasets.set('selected', newSel);
    }
  }

  setSelectedByKey(key, keydata) {
    this.datasets.delete('selected');
    const filteredset = this.datasets.get('set1').filter((item) => {
      return keydata.includes(item[key]);
    });
    this.datasets.set('selected', filteredset);
    this.selectedcards = new Map<string, ComponentRef<Isform2Component>>();
    //this.triggerDetail(filteredset);

    const cards = this.cardlist.filter(item => {
        return keydata.includes(item.instance.data[key])
    });

    cards.forEach((card) => {
      this.selectedcards.set(card.instance.id as any, card.instance as any);

      if (card.instance['setselected']) {
        card.instance['setselected'](true, card);

        card.changeDetectorRef.detectChanges();
      }
      if (!card['ondrawselected']) {
        this.drawSelectedCard(card.instance, false);
      }
    });              

    this.updateCheckboxes();
  }

  fillCell(cell, datapos, col) {
    if (col.selection) {
      (cell.instance as any).checkedVal = this.datasets
        .get('selected')
        ?.includes(this.datax[datapos]);
      cell.instance.updateValue();
      (cell as any).changeDetectorRef.detectChanges();
    } else {
      if (col.cellform) {
        this.updateformData(cell, this.datax[datapos]);
        this.setStylefunctions(
          cell,
          this.rowfunctions.functions,
          this.datax[datapos]
        );
        this.setStylefunctions(cell, col.functions, this.datax[datapos]);
      } else {
        if (
          typeof this.datax[datapos][col.name] != 'object' ||
          this.datax[datapos][col.name] == null
        ) {
          (cell.instance as any)[col.bodycompconfig.data] =
            this.datax[datapos][col.name];
          cell.location.nativeElement.setAttribute('rownumber', datapos);
          /*for(let s of this.columns[i].bodycompconfig.static){
                        (first[i].instance as any)[s.key] = s.value
                    }*/
        }

        // cell.location.nativeElement.innerHTML = (cell.instance as any)[col.bodycompconfig.data]
        //   ? (cell.instance as any)[col.bodycompconfig.data]
        //   : '&nbsp;';

        this.setStylefunctions(
          cell,
          this.rowfunctions.functions,
          this.datax[datapos]
        );
        if (col.functions && col.functions.length > 0) {
          this.setStylefunctions(cell, col.functions, this.datax[datapos]);
        }

        cell.instance['data'] = this.datax[datapos];

        if (col.attribsetter){
          if (this.gridref[col.attribsetter]) {
            this.gridref[col.attribsetter](col,cell,this.datax[datapos])
          }
        }  

        (cell as any).changeDetectorRef.detectChanges();
      }
    }
  }

  addCell(column, data) {
    data.datum = column;
    data.rendered = null;
    //console.log(column,data)
    this.data.push(data);

    if (this.virtualscroll) {
      this.data.splice(this.scrolledItems, 0, data);
      this.scrolledItems++;
      this.moveViewUp(null, column.instance.colid);
    } else {
      this.fillData();
    }

    //this.clearGrid()
    //
  }

  calcPivotpart(key, colkey) {
    /* refresh footer */
    const oldPivotrange = this.columns[key].pivots[0].pivot;
    this.columns[key].pivots[0].pivot = this.columns[
      key
    ].pivots[0].pivot.filter((d) => {
      return (d.value?d.value:d.val) == colkey;
    });
    const pivotData = this.reOrderdata(
      this.bodyCompRef[key][colkey].instance['items'],
      this.columns[key].pivots
    );
    this.columns[key].pivots[0].pivot = oldPivotrange;

    if (this.columns[key].pivots[0].accumulators) {
      this.columns[key].pivots[0].accumulators.forEach((accumulator) => {
        this.bodyCompRef[key][colkey][
          `tot${accumulator.field}`
        ].instance.label =
          accumulator.label +
          pivotData[`${colkey};tot${accumulator.field}`]?.toString();
      });
    }
  }

  insertPivotCell(id, key, colkey, cell) {
    if (this.columns[key].pivots) {
      this.bodyCompRef[key][colkey].instance['items'].push(cell[1]);
      this.calcPivotpart(key, colkey);
      this.bodyCompRef[key][colkey].instance.columnContainer.insert(cell[0]);
      this.bodyCompRef[key][colkey].changeDetectorRef.detectChanges();
    }
  }

  removeCard(id, key, colkey) {
    if (this.columns[key].pivots) {
      /* make this a recursive loop for multilevel pivots (if ever needed) */
      const items = this.bodyCompRef[key][colkey].instance['items'];
      const idx = items.findIndex((d) => {
        return d[key] == id;
      });
      this.bodyCompRef[key][colkey].instance['items'].splice(idx, 1);
      this.bodyCompRef[key][colkey].instance.columnContainer.remove(idx);
      this.bodyCompRef[key][colkey].changeDetectorRef.detectChanges();
      this.calcPivotpart(key, colkey);
    }
  }

  updateCard(id, key, colkey?, newdata?) {
    if (this.columns[key].pivots) {
      /* make this a recursive loop for multilevel pivots (if ever needed) */
      
      // const items = this.bodyCompRef[key][colkey].instance['items'];
      // const idx = items.findIndex((d) => {
      //   return d[key] == id;
      // });

      // const itemRef =
      //   this.bodyCompRef[key][colkey].instance.columnContainer.get(idx);
      // const compRef = this.comp.widgets.get(itemRef._view[8].id);

      const compRef = this.cardlist.find(
          (c: ComponentRef<any>) => {return c.instance.data[key] == id}
      );

      compRef.instance.data = newdata;
      compRef.changeDetectorRef.detectChanges();
      this.bodyCompRef[key][colkey].changeDetectorRef.detectChanges();
      this.calcPivotpart(key, colkey);
    } else {
      const selection = this.data.filter((d) => {
        return d.datum == colkey;
      });
      const idx = selection.findIndex((s) => {
        return s[key] == id;
      });
      this.bodyCompRef[colkey].instance.columnContainer.toArray()[idx];
    }
  }

  removeCell(id, key, colkey?, removedata?) {
    const remove = this.data.findIndex((d) => {
      return d[key] == id;
    });
    let removed = this.data[remove];
    const selection = this.data.filter((d) => {
      return d.datum == colkey;
    });

    //console.log(this.data)
    let itemRef = null;
    if (this.virtualscroll) {
      this.selectedRowId--;
      this.moveViewDown(remove - this.scrolledItems, null);
      this.scrolledItems--;
    } else {
      if (this.columns[key].pivots) {
        /* make this a recursive loop for multilevel pivots (if ever needed) */
        const items = this.bodyCompRef[key][colkey].instance['items'];
        const idx = items.findIndex((d) => {
          return d[key] == id;
        });
        removed = items[idx];
        this.bodyCompRef[key][colkey].instance['items'] = items.filter((d) => {
          return d[key] != id;
        });
        this.calcPivotpart(key, colkey);
        itemRef =
          this.bodyCompRef[key][colkey].instance.columnContainer.detach(idx);
        this.bodyCompRef[key][colkey].changeDetectorRef.detectChanges();
      } else {
        const idx = selection.findIndex((s) => {
          return s[key] == id;
        });
        this.bodyCompRef[colkey].instance.columnContainer.detach(idx);
      }
    }
    if (removedata) {
      this.data = this.data.filter((d) => {
        return d[key] != id;
      });
    }
    return [itemRef, removed];
  }

  setRowIdInInstance(item, rowid) {
    item.instance.rowId = this.datax[rowid];
    //const val = this.comp.formcontrols.get(this.gridid.toString() + '.select')?.formcontrol.value;
    const val = this.datasets.get('selected');
    item.instance.checkedVal = val?.includes(this.datax[rowid]);
    item.changeDetectorRef.detectChanges();
  }

  moveRowToToporBottom(removeidx, addidx, rowRef, datapos) {
    for (const [key, col] of Object.entries(this.bodyCompRef)) {
      if ((col as any).instance.columnContainer.length > 0) {
        (col as any).instance.columnContainer.detach(removeidx ? removeidx : 0);
        //              (col as any).changeDetectorRef.detectChanges()

        const coldef: any = this.columns[key];
        this.fillCell(rowRef[key], datapos, coldef);

        if (coldef.name == 'select') {
          this.setRowIdInInstance(rowRef[key], datapos);
        }
        (col as any).instance.columnContainer.insert(
          (rowRef[key] as any).hostView,
          addidx != null ? addidx : (col as any).instance.columnContainer.length
        );

        //rowRef[key].location.nativeElement.setAttribute('rownumber', datapos);

        const event = new Event('afterdatarendered');
        rowRef[key].location.nativeElement.dispatchEvent(event);
      }
    }
  }

  reorderRowNumbers() {
    let pos = 0;
    this.celCompRef.map((cels: any) => {
      Object.values(cels).map((c: ComponentRef<any>) => {
        c.instance.rownumber = pos;
      });
      pos++;
    });
  }

  refreshSelectedRow(key, value) {
    this.clearGrid();
    this.setData(this.data);
    this.fillData(this.data);
    this.setInitalRow(key, value);
  }


  moveViewDown(keepScrollPos?, idx?, colid?, resetmarking?) {
    if (resetmarking) {
      this.removemarkingSearch('a');
    }
    if (this.scrolledItems + this.numberOfRows < this.datax.length) {
      const oldSelected = this.selectedRowId;

      const datapos = this.selectedRowId + this.scrolledItems + 1;

      const rowid = idx ? idx : 0;

      const rowRef = this.celCompRef[rowid];
      if (rowRef) {
        if (rowid) {
          this.celCompRef.splice(rowid, 1);
        } else {
          this.celCompRef.shift();
        }
      } else {
        // debugger
      }

      this.moveRowToToporBottom(rowid, null, rowRef, datapos);

      this.celCompRef.push(rowRef);
      this.reorderRowNumbers();
      this.scrolledItems++;

      if (keepScrollPos) {
        const currentlySelected = this.selectedRowId;
        this.selectedRowId =
          this.selectedRowId == 0
            ? this.numberOfRows - 1
            : this.selectedRowId - 1;
        this.selectRow(currentlySelected, colid, this.selectedRowId == 0);
        this.selectedRowId = this.selectedRowId;
      } else {
        this.selectedRowId = this.numberOfRows - 2;
        this.selectRow(this.numberOfRows - 1, colid, this.selectedRowId == 0);
      }

      //this.selectedRowId = this.numberOfRows-1   RPR
      //this.selectRow(this.selectedRowId)         RPR

      Object.values(this.bodyCompRef).map((b) => {
        (b as any).changeDetectorRef.detectChanges();
      });
      this.updateScrollbarPosition();
      //let maxTop = this.bodyCompRef[0].location.nativeElement.getBoundingClientRect().height - this.scrollbar.getBoundingClientRect().height

      //this.scrollbar.style.top = (40+maxTop*(this.scrolledItems/(this.data.length-this.numberOfRows))).toString()+'px'
      return 0;
    } else {
      return 1;
    }
  }

  moveViewUp(keepScrollPos?, colid?, resetmarking?) {
    if (resetmarking) {
      this.removemarkingSearch('b');
    }
    if (this.scrolledItems > 0) {
      const rowRef = this.celCompRef[this.celCompRef.length - 1];
      const rowid = this.celCompRef.length - 1;
      this.celCompRef.pop();
      const datapos = this.scrolledItems - 1;

      this.moveRowToToporBottom(rowid, 0, rowRef, datapos);

      this.celCompRef.unshift(rowRef);
      this.reorderRowNumbers();

      this.scrolledItems--;

      if (keepScrollPos) {
        const currentlySelected = this.selectedRowId;
        this.selectedRowId =
          this.selectedRowId == this.numberOfRows - 1
            ? 0
            : this.selectedRowId + 1;
        this.selectRow(currentlySelected, colid, this.selectedRowId == 0);
        this.selectedRowId = this.selectedRowId;
      } else {
        //this.selectedRowId = 0;
        //this.selectRow(0, colid, this.selectedRowId == 0);
        this.selectedRowId = 1;
        this.selectRow(0, colid);
      }

      Object.values(this.bodyCompRef).map((b) => {
        (b as any).changeDetectorRef.detectChanges();
      });

      this.updateScrollbarPosition();

      //let maxTop = this.bodyCompRef[0].location.nativeElement.getBoundingClientRect().height - this.scrollbar.getBoundingClientRect().height
      //this.scrollbar.style.top = (40+maxTop*(this.scrolledItems/(this.data.length-this.numberOfRows))).toString()+'px'
      return 0;
    } else {
      return 1;
    }
  }

  updateScrollbarPosition() {
    if (this.fakeScroll) {
      const parentHeight = window.getComputedStyle(
        this.fakeScroll.nativeElement.parentElement
      ).height;
      let height =
        Number(parentHeight.substr(0, parentHeight.length - 2)) -
        Math.max(this.scrollbarHeight, 32) -
        4;
      height = height < 20 ? 20 : height;
      const translate = (height * this.scrolledItems) / this.datax.length;
      this.fakeScroll.nativeElement.style.transform = `translateY(${translate}px)`;
    }
  }

  makeDraggable() {
    let trackScrollbar = false;
    let startPos;

    const boundTop =
      this.bodyCompRef[0].location.nativeElement.getBoundingClientRect().top;
    const boundBottom =
      this.bodyCompRef[0].location.nativeElement.getBoundingClientRect().bottom;

    this.activeEvents.push('mousedown');

    this.scrollbar.addEventListener('mousedown', (ev) => {
      trackScrollbar = true;
      startPos = ev.offsetY;
    });

    this.activeEvents.push('mouseup');

    window.addEventListener('mouseup', () => {
      trackScrollbar = false;
    });

    this.activeEvents.push('mousemove');

    window.addEventListener('mousemove', (ev) => {
      if (
        trackScrollbar &&
        ev.clientY - startPos >= boundTop &&
        ev.clientY - startPos + this.scrollbar.getBoundingClientRect().height <=
          boundBottom
      ) {
        this.scrollbar.style.top = (ev.clientY - startPos).toString() + 'px';
      }
    });
  }

  ngOnDestroy() {
    this.#subs.unsubscribe();
  }
}
