<div istag="wizard" class="wizard">
  <div istag="wiztitle">
    <islabel istag="wiztitle_label" #wiztitle class="wiztitle_label" [label]="title"></islabel>
  </div>

  <div istag="wizoutlet" class="outlet">
    <ng-template #wizardoutlet></ng-template>
  </div>

  <div istag="wizbuttons" class="wizbuttons">
    <isbutton #wizcancel istag="wizbtn_cancel" class="isbutton" [text]="closebtnlabel" *ngIf="!btnIcons" (click)="cancel()"></isbutton>
    <isbutton
      #wizback
      *ngIf="!btnIcons"
      istag="wizbtn_back"
      class="isbutton"
      hidden
      [text]="lastpage_backtext()"
      (click)="back()"
    ></isbutton>
    <isbutton
      #wiznext
      *ngIf="!btnIcons"
      istag="wizbtn_next"
      class="isbutton"
      [text]="lastpage_text()"
      (click)="index === children.length - 1 ? save() : next()"
    >
    </isbutton>
    <span
      *ngIf="btnIcons"
      class="material-icons isbutton"
      istag="wizbtn_cancel"
      (click)="closewizard()"
    >
      close
    </span>
    <span
      *ngIf="btnIcons"
      istag="wizbtn_back"
      (click)="index !== 0 ? back() : null"
    >
      Vorige
    </span>
    <span
      *ngIf="btnIcons"
      istag="wizbtn_next"
      (click)="index !== children.length - 1 ? next() : null"
    >
      Volgende
    </span>
    <button
      *ngIf="btnIcons"
      class="material-icons savebutton"
      istag="wizbtn_save"
      [disabled]="index !== children.length - 1"
      (click)="index === children.length - 1 ? save() : null"
    >
      save
  </button>
  </div>
</div>
